var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mokuai5",style:({
    top: _vm.item.top + 'px',
    left: _vm.item.left + 'px',
    width: _vm.item.width + 'px',
    height: _vm.item.height + 'px',
    'font-size': _vm.item.fontSize + 'px',
  }),attrs:{"id":_vm.item.id}},[_c('div',{staticClass:"itemBox"},[(!_vm.isImg)?_c('span',{staticClass:"itemBoxDel"},[_vm._v("X")]):_vm._e(),(_vm.item.fixedRatio == 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRightBottom"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRight"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxBottom"}):_vm._e(),_c('div',{staticClass:"itemBoxComparison"},[_c('div',{staticClass:"dataScreenBox"},[_c('span',{staticClass:"dataScreenSpan"},[_vm._v(_vm._s(_vm.dateText))]),_c('span',{staticClass:"dataScreenSpan",staticStyle:{"margin":"0 15px"}},[_vm._v(_vm._s(_vm.timeText))]),_c('span',{staticClass:"dataScreenSpan"},[_vm._v(_vm._s(_vm.weekText))])])]),_c('div',{staticClass:"itemBoxComparisonTop"}),_c('div',{staticClass:"itemBoxComparisonBottom"}),_c('div',{staticClass:"itemBoxComparisonRight"}),_c('div',{staticClass:"itemBoxComparisonLeft"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }