var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mokuai3",style:({
    top: _vm.item.top + 'px',
    left: _vm.item.left + 'px',
    width: _vm.item.width + 'px',
    height: _vm.item.height + 'px',
    'font-size': _vm.item.fontSize + 'px',
  }),attrs:{"id":_vm.item.id}},[_c('div',{staticClass:"itemBox"},[(!_vm.isImg)?_c('span',{staticClass:"itemBoxDel"},[_vm._v("X")]):_vm._e(),(_vm.item.fixedRatio == 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRightBottom"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRight"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxBottom"}):_vm._e(),_c('div',{staticClass:"itemBoxComparison"},[(_vm.isImg)?_c('img',{attrs:{"src":_vm.videoImg,"alt":""}}):_c('div',[_vm._l((_vm.weather.weatherImg),function(item,index){return _c('img',{key:index,staticClass:"weatherImg",attrs:{"src":item,"alt":""}})}),_c('span',{staticClass:"weatherSpan"},[_vm._v(_vm._s(_vm.weather.area))]),_c('span',{staticClass:"weatherSpan"},[_vm._v(_vm._s(_vm.weather.temperature))]),_c('span',{staticClass:"weatherSpan"},[_vm._v(_vm._s(_vm.weather.weather))])],2)]),_c('div',{staticClass:"itemBoxComparisonTop"}),_c('div',{staticClass:"itemBoxComparisonBottom"}),_c('div',{staticClass:"itemBoxComparisonRight"}),_c('div',{staticClass:"itemBoxComparisonLeft"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }