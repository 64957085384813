var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mokuai94",style:({
    top: _vm.item.top + 'px',
    left: _vm.item.left + 'px',
    width: _vm.item.width + 'px',
    height: _vm.item.height + 'px',
    'font-size': _vm.item.fontSize + 'px',
  }),attrs:{"id":_vm.item.id}},[_c('div',{staticClass:"itemBox"},[(!_vm.isImg)?_c('span',{staticClass:"itemBoxDel"},[_vm._v("X")]):_vm._e(),(_vm.item.fixedRatio == 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRightBottom"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRight"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxBottom"}):_vm._e(),_c('div',{staticClass:"itemBoxComparison"},[_c('img',{staticClass:"bgImg",attrs:{"src":require("../../assets/img/imgStaly1/study_topbtn_bg01.png"),"alt":""}}),_c('div',{staticClass:"namePosition"},[(_vm.item.name === 'mokuai94')?_c('span',{staticClass:"title",staticStyle:{"font-size":"1.2em","margin-left":"0.5em"}},[_vm._v(" 东310 ")]):(_vm.item.name === 'mokuai95')?_c('span',{staticClass:"title",staticStyle:{"font-size":"1.2em","margin-left":"0.5em"}},[_c('span',{staticStyle:{"margin-right":"1em"}},[_vm._v("第一节课")]),_vm._v("08:00~09:00 ")]):_vm._e()])]),_c('div',{staticClass:"itemBoxComparisonTop"}),_c('div',{staticClass:"itemBoxComparisonBottom"}),_c('div',{staticClass:"itemBoxComparisonRight"}),_c('div',{staticClass:"itemBoxComparisonLeft"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }