var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"bannerModel",style:({
    top: _vm.item.top + 'px',
    left: _vm.item.left + 'px',
    width: _vm.item.width + 'px',
    height: _vm.item.height + 'px',
    'font-size': _vm.item.fontSize + 'px',
  }),attrs:{"id":_vm.item.id}},[_c('div',{staticClass:"itemBox"},[(!_vm.isImg)?_c('span',{staticClass:"itemBoxDel"},[_vm._v("X")]):_vm._e(),(_vm.item.fixedRatio == 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRightBottom"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRight"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxBottom"}):_vm._e(),_c('div',{staticClass:"itemBoxComparison"},[(_vm.imgType == 1 || _vm.imgType == 2 ||_vm.imgType == 3)?_c('div',{staticClass:"bannerModelBox"},[_c('img',{staticClass:"bannerbg",attrs:{"src":require("../../assets/img/imgStaly1/center_frame_bg.png")}}),(_vm.isImg)?_c('img',{staticClass:"bannerMp4",attrs:{"src":_vm.videoImg}}):_vm._e(),((_vm.imgType == 2 || _vm.imgType == 3)&&!_vm.isImg)?_c('video',{staticClass:"bannerMp4",attrs:{"src":_vm.item.mp4,"autoplay":"","loop":"","muted":""},domProps:{"muted":true}}):_vm._e(),(_vm.imgType == 1 && !_vm.isImg)?_c('img',{staticClass:"itemBoxComparison_img",attrs:{"src":_vm.item.img,"alt":""}}):_vm._e()]):_vm._e(),(_vm.isImg)?_c('img',{staticClass:"bannerMp4",attrs:{"src":_vm.videoImg}}):_vm._e(),(_vm.imgType == 4 && !_vm.isImg)?_c('img',{staticStyle:{"width":"100%","height":"100%"},attrs:{"src":_vm.item.img,"alt":""}}):_vm._e()]),_c('div',{staticClass:"itemBoxComparisonTop"}),_c('div',{staticClass:"itemBoxComparisonBottom"}),_c('div',{staticClass:"itemBoxComparisonRight"}),_c('div',{staticClass:"itemBoxComparisonLeft"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }