<template>
  <div class="mokuai71" :id="item.id" :style="{
      top: item.top + 'px',
      left: item.left + 'px',
      width: item.width + 'px',
      height: item.height + 'px',
      'font-size': item.fontSize + 'px',
    }">
    <div class="itemBox">
      <span class="itemBoxDel" v-if="!isImg">X</span>
      <span v-if="item.fixedRatio == 1 && !isImg" class="itemBoxRightBottom"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxRight"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxBottom"></span>
      <div class="itemBoxComparison">
        <div class="wrap">
          <div class="cont">
            <div class="txt">
              高二3班违规使用平板学生:李佳玉,曾博玉高三3班违规使用平板学生:刘睿然,郭金恺高一1班违规使用平板学生:魏博瑞,王永乐高一3班违规使用平板学生:解景元,何羿铭高三1班违规使用平板学生:郭杨帆高三4班违规使用平板学生:韩晨宇高二1班违规使用平板学生:常尘鑫高一2班违规使用平板学生:曹明浩,张天祥
            </div>
          </div>
        </div>
      </div>
      <div class="itemBoxComparisonTop"></div>
      <div class="itemBoxComparisonBottom"></div>
      <div class="itemBoxComparisonRight"></div>
      <div class="itemBoxComparisonLeft"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: "mokuai71",
    props: {
      item: {
        type: Object,
      },
      isImg: {
        type: Boolean
      }
    },
    components: {},
    data() {
      return {
        videoImg: "",
      };
    },
    mounted() {
      const that = this;
      let containerWidth = document.querySelector('.wrap').offsetWidth 
      let boxWidth = document.querySelector('.wrap .cont').offsetWidth 
      let duration = (boxWidth * 2 + containerWidth) / 150 + 's'
      document.querySelector('.wrap .cont').style.cssText = 'animation-duration:' + duration
    }
  }
</script>

<style scoped>
  .mokuai71 {
    position: absolute;
    display: block;
    cursor: move;
  }

  .txt {
    white-space: nowrap;
  }

  .wrap {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    overflow: hidden;
  }

  .cont {
    position: absolute;
    display: flex;
    color: #fff;
    white-space: nowrap;
    animation: marquee 5s linear infinite;
  }

  /* 如果是 PC 端，可能还需要一个鼠标移入时动画暂停的功能 */
  /* .marquee-container:hover .marquee-box {
  animation-play-state: paused;
} */

  @keyframes marquee {
    0% {
      left: 100%;
    }

    100% {
      left: 0%;
      transform: translateX(-100%);
    }
  }
</style>