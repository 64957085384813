
<template>
    <div class="mokuai2" :id="item.id" :style="{'top': item.top + 'px','left': item.left + 'px'}">
        <div class="itemBox">
            <span class="itemBoxDel" v-if="!isImg">X</span>
            <!-- <span class="itemBoxRight"></span>
            <span class="itemBoxBottom"></span> -->
            <div class="itemBoxComparison">
              <div class="footer">
                <img style="max-width: 100%;" :src="videoImg" v-if="isImg" />
                <img style="max-width: 100%;" :src="item.img" v-else>
              </div>
            </div>
            <div class="itemBoxComparisonTop"></div>
            <div class="itemBoxComparisonBottom"></div>
            <div class="itemBoxComparisonRight"></div>
            <div class="itemBoxComparisonLeft"></div>
        </div>
    </div>
  </template>
  <script>
  export default {
    name: 'mokuai2',
    props:{
      item: {
        type: Object,
      },
      isImg: {
        type: Boolean
      }
    },
    components: {},
    data() {
      return {
        videoImg: "",
      }
    },
  mounted() {
    const that = this;
    that.$getBase64(that.item.img, (dataURL) => {
      // console.log("dataURL=>", dataURL);
      that.videoImg = dataURL;
    });
  },
  }
  </script>
  
  <style>
  .mokuai2 {
    position: absolute;
    display: block;
    width: 100%;
    /* height: 50px; */
    cursor: move;
    font-size: 16px;
  }
  .footer{
    display: flex;
    justify-content: center;
  }
  </style>
  