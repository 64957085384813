<template>
  <div class="mokuai41" :id="item.id"
    :style="{'top': item.top + 'px','left': item.left + 'px', 'width': item.width + 'px', 'height': item.height + 'px','font-size': item.fontSize+'px'}">
    <div class="itemBox">
      <span class="itemBoxDel" v-if="!isImg">X</span>
      <span v-if="item.fixedRatio == 1" class="itemBoxRightBottom"></span>
      <span v-if="item.fixedRatio != 1" class="itemBoxRight"></span>
      <span v-if="item.fixedRatio != 1" class="itemBoxBottom"></span>
      <div class="itemBoxComparison">
        <div class="muokuai41Top">
          <span class="topNav" :class="index==0?'muokuai41navActived':''" v-for="(items, index) in item.assessmentNames"
            :key="index">{{items}}</span>
        </div>
        <img :src="videoImg" style="object-fit: fill;width: 100%;height: 85%;" v-if="isImg" />
        <video :src="item.mp4" style="object-fit: fill;width: 100%;height: 85%;" autoplay loop muted v-else>
        </video>
      </div>
      <div class="itemBoxComparisonTop"></div>
      <div class="itemBoxComparisonBottom"></div>
      <div class="itemBoxComparisonRight"></div>
      <div class="itemBoxComparisonLeft"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'mokuai41',
    props: {
      item: {
        type: Object,
      },
      isImg: {
        type: Boolean
      }
    },
    components: {},
    data() {
      return {
        videoImg: "",
      };
    },
    mounted() {
      const that = this;
      that.$getBase64(that.item.img, (dataURL) => {
        // console.log("dataURL=>", dataURL);
        that.videoImg = dataURL;
      });
    },
  }
</script>

<style>
  .mokuai41 {
    position: absolute;
    display: block;
    cursor: move;
    font-size: 16px;
  }

  .muokuai41Top {
    height: 15%;
    display: flex;
    align-items: center;
    background-image: url('../../assets/img/imgStaly1/toggle_btn_bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .topNav {
    height: 100%;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 0;
    color: #CCECF9;
    flex: 1;
  }

  .muokuai41navActived {
    background-image: url('../../assets/img/imgStaly1/toggle_select_btn.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
  
</style>