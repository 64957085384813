<template>
  <div
    class="mokuai3"
    :id="item.id"
    :style="{
      top: item.top + 'px',
      left: item.left + 'px',
      width: item.width + 'px',
      height: item.height + 'px',
      'font-size': item.fontSize + 'px',
    }"
  >
    <div class="itemBox">
      <span class="itemBoxDel" v-if="!isImg">X</span>
      <span v-if="item.fixedRatio == 1 && !isImg" class="itemBoxRightBottom"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxRight"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxBottom"></span>
      <div class="itemBoxComparison">
        <img :src="videoImg" v-if="isImg" alt="" />
        <div v-else>
          <img
            v-for="(item, index) in weather.weatherImg"
            :key="index"
            class="weatherImg"
            :src="item"
            alt=""
          />
          <span class="weatherSpan">{{ weather.area }}</span>
          <span class="weatherSpan">{{ weather.temperature }}</span>
          <span class="weatherSpan">{{ weather.weather }}</span>
        </div>
      </div>
      <div class="itemBoxComparisonTop"></div>
      <div class="itemBoxComparisonBottom"></div>
      <div class="itemBoxComparisonRight"></div>
      <div class="itemBoxComparisonLeft"></div>
    </div>
  </div>
</template>
<script>
import { bigscreenWarningReport } from "@/api/other";
import { axios } from "@/utils/request";
export default {
  name: "mokuai3",
  props: {
    item: {
      type: Object,
    },
    isImg: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      weather: {
        area: "",
        temperature: "",
        weather: "",
        weatherImg: "",
      },
      ip: "",
      videoImg: "",
    };
  },
  methods: {
    async getIpinfo() {
      const that = this
      try {
        const response = await axios.get("https://ipinfo.io/json");
        this.ip = response.ip;
        that.getBigscreenWarningReport();
        setInterval(function () {
          that.getBigscreenWarningReport();
        }, 600000);
      } catch (error) {
        console.error(error);
      }
    },
    getBigscreenWarningReport() {
      bigscreenWarningReport(
        {
          ip: this.ip,
        },
        (resp) => {
          this.weather = resp.data;
        },
        (err) => {
          console.log("err", err);
        }
      );
    },
  },
  mounted() {
    const that = this;
    that.$getBase64(that.item.img, (dataURL) => {
      // console.log("dataURL=>", dataURL);
      that.videoImg = dataURL;
    });
    this.getIpinfo();
  },
};
</script>

<style>
.mokuai3 {
  position: absolute;
  display: block;
  cursor: move;
  font-size: 16px;
}
.weatherImg {
  width: 3em;
}
.weatherSpan {
  font-size: 1em;
  color: #fff;
}
</style>
