<template>
    <div
      class="mokuai61"
      :id="item.id"
      :style="{
        top: item.top + 'px',
        left: item.left + 'px',
        width: item.width + 'px',
        height: item.height + 'px',
        'font-size': item.fontSize + 'px',
      }"
    >
      <div class="itemBox">
        <span class="itemBoxDel" v-if="!isImg">X</span>
        <span v-if="item.fixedRatio == 1 && !isImg" class="itemBoxRightBottom"></span>
        <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxRight"></span>
        <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxBottom"></span>
        <div class="itemBoxComparison">
          <img :src="videoImg" style="width: 100%;height: 100%;object-fit: fill;" v-if="isImg" />
          <img :src="item.img" style="width: 100%;height: 100%;object-fit: fill;" v-if="!isImg"/>
        </div>
        <div class="itemBoxComparisonTop"></div>
        <div class="itemBoxComparisonBottom"></div>
        <div class="itemBoxComparisonRight"></div>
        <div class="itemBoxComparisonLeft"></div>
      </div>
    </div>
  </template>
  <script>
  export default {
    name: "mokuai38",
    props: {
      item: {
        type: Object,
      },
      isImg: {
        type: Boolean
      }
    },
    components: { },
    data() {
      return {
        videoImg: "",
      };
    },
    mounted() {
      const that = this;
      that.$getBase64(that.item.img, (dataURL) => {
      // console.log("dataURL=>", dataURL);
      that.videoImg = dataURL;
    });
      console.log(that.item)
    },
  };
  </script>
  
  <style>
  .mokuai61 {
    position: absolute;
    display: block;
    cursor: move;
    font-size: 16px;
  }
  </style>
  