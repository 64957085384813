<template>
  <div
    class="bannerModel"
    :id="item.id"
    :style="{
      top: item.top + 'px',
      left: item.left + 'px',
      width: item.width + 'px',
      height: item.height + 'px',
      'font-size': item.fontSize + 'px',
    }"
  >
    <div class="itemBox">
      <span class="itemBoxDel" v-if="!isImg">X</span>
      <span v-if="item.fixedRatio == 1 && !isImg" class="itemBoxRightBottom"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxRight"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxBottom"></span>
      <div class="itemBoxComparison">
        <div class="bannerModelBox" v-if="imgType == 1 || imgType == 2 ||imgType == 3">
          <img src="../../assets/img/imgStaly1/center_frame_bg.png" class="bannerbg">
          <img :src="videoImg" class="bannerMp4" v-if="isImg" />
          <video
            class="bannerMp4"
            :src="item.mp4"
            autoplay
            loop
            muted
            v-if="(imgType == 2 || imgType == 3)&&!isImg"
          >
          </video>
          <img
            class="itemBoxComparison_img"
            :src="item.img"
            v-if="imgType == 1 && !isImg"
            alt=""
          />
        </div>
        <img :src="videoImg" class="bannerMp4" v-if="isImg" />
        <img
          style="width: 100%;height: 100%;"
          :src="item.img"
          v-if="imgType == 4 && !isImg"
          alt=""
        />
      </div>
      <div class="itemBoxComparisonTop"></div>
      <div class="itemBoxComparisonBottom"></div>
      <div class="itemBoxComparisonRight"></div>
      <div class="itemBoxComparisonLeft"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "bannerModel",
  props: {
    item: {
      type: Object,
    },
    imgType: {
      type: Number,
    },
    isImg: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      videoImg: "",
    };
  },
  mounted() {
    const that = this;
    console.log('item===>', this.item)
    that.$getBase64(that.item.img, (dataURL) => {
      // console.log("dataURL=>", dataURL);
      that.videoImg = dataURL;
    });
  },
};
</script>

<style>
.bannerModel {
  position: absolute;
  display: block;
  cursor: move;
  font-size: 16px;
}
.bannerModelBox {
  width: 100%;
  height: 100%;
  position: relative;
}
.bannerbg {
  width: 100%;
  height: 100%;
}
.bannerMp4 {
  width: calc(100% - 15%);
  height: calc(100% - 18%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.itemBoxComparison_img {
  width: calc(100% - 15%);
  height: calc(100% - 18%);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
</style>
