<template>
  <div class="mokuai45" :id="item.id"
    :style="{'top': item.top + 'px','left': item.left + 'px', 'width': item.width + 'px', 'height': item.height + 'px','font-size': item.fontSize+'px'}">
    <div class="itemBox">
      <span class="itemBoxDel" v-if="!isImg">X</span>
      <span v-if="item.fixedRatio == 1" class="itemBoxRightBottom"></span>
      <span v-if="item.fixedRatio != 1" class="itemBoxRight"></span>
      <span v-if="item.fixedRatio != 1" class="itemBoxBottom"></span>
      <div class="itemBoxComparison">
        <div class="mokuai45Box">
          <div class="mokuai45Top">
            <div class="mokuai45TopNav" :class="index==0?'mokuai45navActived':''"
              v-for="(items, index) in item.assessmentNames" :key="index">{{items}}</div>
          </div>
          <img :src="videoImg" style="object-fit: fill;width: 100%;height: 85%;" v-if="isImg" />
          <video :src="item.mp4" style="object-fit: fill;width: 100%;height: 85%;" autoplay loop muted v-if="!isImg">
          </video>
        </div>
      </div>
      <div class="itemBoxComparisonTop"></div>
      <div class="itemBoxComparisonBottom"></div>
      <div class="itemBoxComparisonRight"></div>
      <div class="itemBoxComparisonLeft"></div>
    </div>
  </div>
</template>
<script>
  export default {
    name: 'mokuai45',
    props: {
      item: {
        type: Object,
      },
      isImg: {
        type: Boolean
      }
    },
    components: {},
    data() {
      return {
        videoImg: "",
      };
    },
    mounted() {
      const that = this;
      that.$getBase64(that.item.img, (dataURL) => {
        // console.log("dataURL=>", dataURL);
        that.videoImg = dataURL;
      });
    },
  }
</script>

<style>
  .mokuai45 {
    position: absolute;
    display: block;
    cursor: move;
    font-size: 16px;
  }

  .mokuai45Box {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: space-between;
    flex-wrap: wrap;
  }

  .mokuai45Top {
    height: 10%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../../assets/img/imgStaly1/jiaoxue_toggle_bg.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }

  .mokuai45TopNav {
    height: 100%;
    font-size: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #CCECF9;
    padding: 0 1.5em;
  }

  .mokuai45navActived {
    background-image: url('../../assets/img/imgStaly1/toggle_select_btn01.png');
    background-position: center;
    background-size: 100% 100%;
    background-repeat: no-repeat;
  }
</style>