var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mokuai71",style:({
    top: _vm.item.top + 'px',
    left: _vm.item.left + 'px',
    width: _vm.item.width + 'px',
    height: _vm.item.height + 'px',
    'font-size': _vm.item.fontSize + 'px',
  }),attrs:{"id":_vm.item.id}},[_c('div',{staticClass:"itemBox"},[(!_vm.isImg)?_c('span',{staticClass:"itemBoxDel"},[_vm._v("X")]):_vm._e(),(_vm.item.fixedRatio == 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRightBottom"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRight"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxBottom"}):_vm._e(),_vm._m(0),_c('div',{staticClass:"itemBoxComparisonTop"}),_c('div',{staticClass:"itemBoxComparisonBottom"}),_c('div',{staticClass:"itemBoxComparisonRight"}),_c('div',{staticClass:"itemBoxComparisonLeft"})])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"itemBoxComparison"},[_c('div',{staticClass:"wrap"},[_c('div',{staticClass:"cont"},[_c('div',{staticClass:"txt"},[_vm._v(" 高二3班违规使用平板学生:李佳玉,曾博玉高三3班违规使用平板学生:刘睿然,郭金恺高一1班违规使用平板学生:魏博瑞,王永乐高一3班违规使用平板学生:解景元,何羿铭高三1班违规使用平板学生:郭杨帆高三4班违规使用平板学生:韩晨宇高二1班违规使用平板学生:常尘鑫高一2班违规使用平板学生:曹明浩,张天祥 ")])])])])
}]

export { render, staticRenderFns }