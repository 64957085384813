<template>
  <div
    class="mokuai102"
    :id="item.id"
    :style="{
      top: item.top + 'px',
      left: item.left + 'px',
      width: item.width + 'px',
      height: item.height + 'px',
      'font-size': item.fontSize + 'px',
    }"
  >
    <div class="itemBox">
      <span class="itemBoxDel" v-if="!isImg">X</span>
      <span v-if="item.fixedRatio == 1 && !isImg" class="itemBoxRightBottom"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxRight"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxBottom"></span>
      <div class="itemBoxComparison top">
        <div
          class="titlePositionCenter"
          style="height: 10%;left: 10%;justify-content: left;"
        >
          <span class="title" style="font-size: 1.2em;z-index: 2;">{{ item.title }}</span>
        </div>
        <img :src="videoImg" class="mokuaiMp4Position" style="width: 100%; height: 100%;bottom: 0;" v-if="isImg" />
        <video :src="item.mp4" class="mokuaiMp4Position" style="width: 100%; height: 100%;bottom: 0;" autoplay loop muted v-else>
        </video>
      </div>
      <div class="itemBoxComparisonTop"></div>
      <div class="itemBoxComparisonBottom"></div>
      <div class="itemBoxComparisonRight"></div>
      <div class="itemBoxComparisonLeft"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mokuai102",
  props: {
    item: {
      type: Object,
    },
    isImg: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      videoImg: "",
    };
  },
  mounted() {
    const that = this;
    that.$getBase64(that.item.img, (dataURL) => {
      // console.log("dataURL=>", dataURL);
      that.videoImg = dataURL;
    });
  },
};
</script>

<style>
.mokuai102 {
  position: absolute;
  display: block;
  cursor: move;
  font-size: 16px;
  text-align: center;
}
</style>
