var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mokuai92",style:({
    top: _vm.item.top + 'px',
    left: _vm.item.left + 'px',
    width: _vm.item.width + 'px',
    height: _vm.item.height + 'px',
    'font-size': _vm.item.fontSize + 'px',
  }),attrs:{"id":_vm.item.id}},[_c('div',{staticClass:"itemBox"},[(!_vm.isImg)?_c('span',{staticClass:"itemBoxDel"},[_vm._v("X")]):_vm._e(),(_vm.item.fixedRatio == 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRightBottom"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRight"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxBottom"}):_vm._e(),_c('div',{staticClass:"itemBoxComparison top"},[_c('div',{staticClass:"titlePositionCenter",staticStyle:{"height":"15%","left":"1em","justify-content":"left"}},[_c('span',{staticClass:"title",staticStyle:{"font-size":"1.2em","z-index":"2"}},[_vm._v(_vm._s(_vm.item.title))])]),(_vm.isImg)?_c('img',{staticClass:"mokuaiMp4Position",staticStyle:{"width":"100%","height":"100%","bottom":"0"},attrs:{"src":_vm.videoImg}}):_c('video',{staticClass:"mokuaiMp4Position",staticStyle:{"width":"100%","height":"100%","bottom":"0"},attrs:{"src":_vm.item.mp4,"autoplay":"","loop":"","muted":""},domProps:{"muted":true}})]),_c('div',{staticClass:"itemBoxComparisonTop"}),_c('div',{staticClass:"itemBoxComparisonBottom"}),_c('div',{staticClass:"itemBoxComparisonRight"}),_c('div',{staticClass:"itemBoxComparisonLeft"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }