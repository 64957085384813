var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"mokuai22",style:({
    top: _vm.item.top + 'px',
    left: _vm.item.left + 'px',
    width: _vm.item.width + 'px',
    height: _vm.item.height + 'px',
    'font-size': _vm.item.fontSize + 'px',
  }),attrs:{"id":_vm.item.id}},[_c('div',{staticClass:"itemBox"},[(!_vm.isImg)?_c('span',{staticClass:"itemBoxDel"},[_vm._v("X")]):_vm._e(),(_vm.item.fixedRatio == 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRightBottom"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxRight"}):_vm._e(),(_vm.item.fixedRatio != 1 && !_vm.isImg)?_c('span',{staticClass:"itemBoxBottom"}):_vm._e(),_c('div',{staticClass:"itemBoxComparison"},[_c('div',{staticClass:"top",staticStyle:{"height":"15%"}},[_c('TitlesImg',{attrs:{"type":_vm.item.titleImgType}}),_c('div',{staticClass:"titlePosition",staticStyle:{"margin-left":"2em"}},[_c('span',{staticClass:"title"},[_vm._v(_vm._s(_vm.item.title))])])],1),(_vm.isImg)?_c('img',{staticStyle:{"object-fit":"fill","width":"100%","height":"85%"},attrs:{"src":_vm.videoImg}}):_c('video',{staticStyle:{"object-fit":"fill","width":"100%","height":"85%"},attrs:{"src":_vm.item.mp4,"autoplay":"","loop":"","muted":""},domProps:{"muted":true}})]),_c('div',{staticClass:"itemBoxComparisonTop"}),_c('div',{staticClass:"itemBoxComparisonBottom"}),_c('div',{staticClass:"itemBoxComparisonRight"}),_c('div',{staticClass:"itemBoxComparisonLeft"})])])
}
var staticRenderFns = []

export { render, staticRenderFns }