<template>
  <div
    class="mokuai14"
    :id="item.id"
    :style="{
      top: item.top + 'px',
      left: item.left + 'px',
      width: item.width + 'px',
      height: item.height + 'px',
      'font-size': item.fontSize + 'px',
    }"
  >
    <div class="itemBox">
      <span class="itemBoxDel" v-if="!isImg">X</span>
      <span v-if="item.fixedRatio == 1 && !isImg" class="itemBoxRightBottom"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxRight"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxBottom"></span>
      <div class="itemBoxComparison">
        <div style="width: 100%;height: 100%;display: flex;flex-direction:column;justify-content: space-between;">
          <div class="top" style="height: 20%;">
            <TitlesImg :type="item.titleImgType" />
            <div class="titlePosition" style="margin-left: 2em;">
              <span class="title">{{ item.title }}</span>
              <span class="title1"
                >(考查学生对重难点试题攻坚克难的成绩，同时也考查老师指导学生“歼灭学习重难点”的效果)</span
              >
            </div>
          </div>
          <img :src="videoImg" style="width: 100%;height: 80%;" v-if="isImg" />
          <video :src="item.mp4" style="width: 100%;height: 80%;object-fit: fill;" autoplay loop muted v-else>
          </video>
        </div>
      </div>
      <div class="itemBoxComparisonTop"></div>
      <div class="itemBoxComparisonBottom"></div>
      <div class="itemBoxComparisonRight"></div>
      <div class="itemBoxComparisonLeft"></div>
    </div>
  </div>
</template>
<script>
import TitlesImg from '@/components/CustomModule/TitlesImg.vue'
export default {
  name: "mokuai14",
  components: { TitlesImg },
  props: {
    item: {
      type: Object,
    },
    isImg: {
      type: Boolean
    }
  },
  data() {
    return {
      videoImg: "",
    };
  },
  mounted() {
    const that = this;
    that.$getBase64(that.item.img, (dataURL) => {
      // console.log("dataURL=>", dataURL);
      that.videoImg = dataURL;
    });
  },
};
</script>

<style>
.mokuai14 {
  position: absolute;
  display: block;
  cursor: move;
  font-size: 16px;
}
</style>
