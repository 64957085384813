<template>
  <div
    class="mokuai65"
    :id="item.id"
    :style="{
      top: item.top + 'px',
      left: item.left + 'px',
      width: item.width + 'px',
      height: item.height + 'px',
      'font-size': item.fontSize + 'px',
    }"
  >
    <div class="itemBox">
      <span class="itemBoxDel" v-if="!isImg">X</span>
      <span v-if="item.fixedRatio == 1 && !isImg" class="itemBoxRightBottom"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxRight"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxBottom"></span>
      <div class="itemBoxComparison">
        <div class="text">
          {{ item.title }}
        </div>
      </div>
      <div class="itemBoxComparisonTop"></div>
      <div class="itemBoxComparisonBottom"></div>
      <div class="itemBoxComparisonRight"></div>
      <div class="itemBoxComparisonLeft"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mokuai65",
  props: {
    item: {
      type: Object,
    },
    isImg: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      videoImg: "",
    };
  },
  mounted() {
  },
};
</script>

<style>
.mokuai65 {
  position: absolute;
  display: block;
  cursor: move;
}
.text {
  width: 100%;
  height: 100%;
  font-size: 1em;
  word-wrap:break-word;
  word-break:normal;
  overflow: hidden;
  color: #bff7fe;
}
</style>
