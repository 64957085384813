<template>
  <div class="SetBigScreet">
    <div class="SetBigScreetTop">
      <div class="SetBigScreetTopLeft" @click="goBack()">
        <a-icon type="left" /> 民办学校大数据可视化管理系统
      </div>
      <div class="SetBigScreetTopRight">
        <div class="SetBigScreetTopRightBtn" @click="isAuxiliary=!isAuxiliary">
          <a-icon type="minus" style="font-size: 0.7vw;" />
          辅助线
        </div>
        <div class="SetBigScreetTopRightBtn" @click="clear()">
          <a-icon type="delete" style="font-size: 0.7vw;" />
          清空
        </div>
        <div class="SetBigScreetTopRightBtn" @click="snapshoot(2)">
          <img src="../assets/img/imgStaly1/save_icon.png" style="width: 0.7vw;" alt="">
          保存
        </div>
        <div class="SetBigScreetTopRightBtn" v-if="currentSubSchoolId !== '0'" @click="goPreview">
          <img src="../assets/img/imgStaly1/preview_icon.png" style="width: 0.7vw;" alt="">
          预览
        </div>
        <div class="SetBigScreetTopRightBtn" @click="snapshoot(1)" v-if="currentSubSchoolId !== '0'">
          <img src="../assets/img/imgStaly1/publish_icon.png" style="width: 0.7vw;" alt="">
          发布
        </div>
      </div>
    </div>
    <div class="SetBigScreetBox">
      <div class="SetBigScreetLeft">
        <div class="SetBigScreetLeftTop">
          <div class="SetBigScreetLeftTopTab">
            <div :class="tabType == '0' ? 'tab' : ''" @click="tabClick('0')">大屏模块</div>
            <div :class="tabType == '1' ? 'tab' : ''" @click="tabClick('1')">组件素材</div>
          </div>
        </div>
        <div class="SetBigScreetLeftList">
          <div v-for="(item, index) in modelsList" style="width: 100%;" :key="index" :id="item.id">
            <div style="color: #fff;margin-left: 5px;font-size: 0.7vw;height: 1vw;">
              {{ item.modelName }}
            </div>
            <div v-if="item.components.length > 0" class="SetBigScreetItemList">
              <div v-for="items in item.components" :key="items.componentId" class="SetBigScreetLeftBox">
                <div class="SetBigScreetLeftForbid" v-if="item.lock">
                  <img src="https://onlinelesson-img.oss-cn-beijing.aliyuncs.com/Image/1693367009298.png" width="20px"
                    height="20px" alt="">
                </div>
                <div class="SetBigScreetLeftItem" draggable @dragend="dragend" :id="items.componentId">
                  <div class="SetBigScreetLeftItemImg">
                    <img :src="items.img" alt="" height="90%">
                  </div>
                  <div class="SetBigScreetLeftItemName">
                    <a-tooltip>
                      <template slot="title">
                        {{ items.componentName }}
                      </template>
                        {{ items.componentName }}
                    </a-tooltip>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="SetBigScreetCenter">
        <div id="screen" class="cesScreen"
          :style="{'width': width + 'px','height': height + 'px','background-image': `url(${bagBase64})`}">
          <div v-if="isAuxiliary">
            <div
              style="width: 100%;height: 1px;position: absolute;top: 0;bottom: 0;left: 0;right: 0;margin: auto;border-bottom: 1px solid #fff;">
            </div>
            <div
              style="width: 1px;height: 100%;position: absolute;top: 0;bottom: 0;left: 25%;margin: auto;border-left: 1px solid #fff;">
            </div>
            <div
              style="width: 1px;height: 100%;position: absolute;top: 0;bottom: 0;left: 50%;margin: auto;border-left: 1px solid #fff;">
            </div>
            <div
              style="width: 1px;height: 100%;position: absolute;top: 0;bottom: 0;left: 75%;margin: auto;border-left: 1px solid #fff;">
            </div>
          </div>
          <div v-for="(item, index) in list2" :key="index">
            <mokuai1 :item="item" :isImg="isImg" v-if="item.name == 'mokuai1'" />
            <mokuai1 :item="item" :isImg="isImg" v-if="item.name == 'mokuai33'" />
            <mokuai1 :item="item" :isImg="isImg" v-if="item.name == 'mokuai25'" />
            <mokuai1 :item="item" :isImg="isImg" v-if="item.name == 'mokuai31'" />
            <mokuai1 :item="item" :isImg="isImg" v-if="item.name == 'mokuai53'" />
            <mokuai1 :item="item" :isImg="isImg" v-if="item.name == 'mokuai66'" />
            <mokuai1 :item="item" :isImg="isImg" v-if="item.name == 'mokuai67'" />
            <mokuai1 :item="item" :isImg="isImg" v-if="item.name == 'mokuai68'" />
            <mokuai1 :item="item" :isImg="isImg" v-if="item.name == 'mokuai99'" />
            <mokuai2 :item="item" :isImg="isImg" v-if="item.name == 'mokuai2'" />
            <mokuai2 :item="item" :isImg="isImg" v-if="item.name == 'mokuai26'" />
            <mokuai2 :item="item" :isImg="isImg" v-if="item.name == 'mokuai69'" />
            <mokuai2 :item="item" :isImg="isImg" v-if="item.name == 'mokuai100'" />
            <mokuai3 :item="item" :isImg="isImg" v-if="item.name == 'mokuai3'" />
            <mokuai5 :item="item" :isImg="isImg" v-if="item.name == 'mokuai5'" />
            <mokuai10 :item="item" :isImg="isImg" v-if="item.name == 'mokuai10'" />
            <mokuai10 :item="item" :isImg="isImg" v-if="item.name == 'mokuai32'" />
            <mokuai10 :item="item" :isImg="isImg" v-if="item.name == 'mokuai52'" />
            <mokuai10 :item="item" :isImg="isImg" v-if="item.name == 'mokuai54'" />
            <mokuai10 :item="item" :isImg="isImg" v-if="item.name == 'mokuai56'" />
            <mokuai10 :item="item" :isImg="isImg" v-if="item.name == 'mokuai60'" />
            <mokuai10 :item="item" :isImg="isImg" v-if="item.name == 'mokuai62'" />
            <mokuai11 :item="item" :isImg="isImg" v-if="item.name == 'mokuai11'" />
            <mokuai12 :item="item" :isImg="isImg" v-if="item.name == 'mokuai12'" />
            <mokuai13 :item="item" :isImg="isImg" v-if="item.name == 'mokuai13'" />
            <mokuai14 :item="item" :isImg="isImg" v-if="item.name == 'mokuai14'" />
            <mokuai15 :item="item" :isImg="isImg" v-if="item.name == 'mokuai15'" />
            <mokuai16 :item="item" :isImg="isImg" v-if="item.name == 'mokuai16'" />
            <mokuai40 :item="item" :isImg="isImg" v-if="item.name == 'mokuai40'" />
            <mokuai40 :item="item" :isImg="isImg" v-if="item.name == 'mokuai57'" />
            <mokuai17 :item="item" :isImg="isImg" v-if="item.name == 'mokuai17'" />
            <mokuai17 :item="item" :isImg="isImg" v-if="item.name == 'mokuai47'" />
            <mokuai17 :item="item" :isImg="isImg" v-if="item.name == 'mokuai51'" />
            <mokuai34 :item="item" :isImg="isImg" v-if="item.name == 'mokuai34'" />
            <mokuai18 :item="item" :isImg="isImg" v-if="item.name == 'mokuai18'" />
            <mokuai35 :item="item" :isImg="isImg" v-if="item.name == 'mokuai35'" />
            <mokuai19 :item="item" :isImg="isImg" v-if="item.name == 'mokuai19'" />
            <mokuai20 :item="item" :isImg="isImg" v-if="item.name == 'mokuai20'" />
            <mokuai36 :item="item" :isImg="isImg" v-if="item.name == 'mokuai36'" />
            <mokuai37 :item="item" :isImg="isImg" v-if="item.name == 'mokuai37'" />
            <mokuai21 :item="item" :isImg="isImg" v-if="item.name == 'mokuai21'" />
            <mokuai22 :item="item" :isImg="isImg" v-if="item.name == 'mokuai22'" />
            <mokuai23 :item="item" :isImg="isImg" v-if="item.name == 'mokuai23'" />
            <bannerModel :item="item" :isImg="isImg" :imgType="2" v-if="item.name == 'mokuai7'" />
            <bannerModel :item="item" :isImg="isImg" :imgType="1" v-if="item.name == 'mokuai8'" />
            <bannerModel :item="item" :isImg="isImg" :imgType="4" v-if="item.name == 'mokuai30'" />
            <bannerModel :item="item" :isImg="isImg" :imgType="3" v-if="item.name == 'mokuai9'" />
            <mokuai27 :item="item" :isImg="isImg" v-if="item.name == 'mokuai27'" />
            <mokuai27 :item="item" :isImg="isImg" v-if="item.name == 'mokuai28'" />
            <mokuai29 :item="item" :isImg="isImg" v-if="item.name == 'mokuai29'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai38'" />
            <mokuai27 :item="item" :isImg="isImg" v-if="item.name == 'mokuai39'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai43'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai44'" />
            <mokuai41 :item="item" :isImg="isImg" v-if="item.name == 'mokuai41'" />
            <mokuai45 :item="item" :isImg="isImg" v-if="item.name == 'mokuai45'" />
            <mokuai42 :item="item" :isImg="isImg" v-if="item.name == 'mokuai42'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai46'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai48'" />
            <mokuai50 :item="item" :isImg="isImg" v-if="item.name == 'mokuai50'" />
            <mokuai58 :item="item" :isImg="isImg" v-if="item.name == 'mokuai58'" />
            <mokuai59 :item="item" :isImg="isImg" v-if="item.name == 'mokuai59'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai55'" />
            <mokuai61 :item="item" :isImg="isImg" v-if="item.name == 'mokuai61'" />
            <mokuai61 :item="item" :isImg="isImg" v-if="item.name == 'mokuai64'" />
            <mokuai61 :item="item" :isImg="isImg" v-if="item.name == 'mokuai63'" />
            <mokuai65 :item="item" :isImg="isImg" v-if="item.name == 'mokuai65'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai70'" />
            <mokuai71 :item="item" :isImg="isImg" v-if="item.name == 'mokuai71'" />
            <mokuai72 :item="item" :isImg="isImg" v-if="item.name == 'mokuai72'" />
            <mokuai73 :item="item" :isImg="isImg" v-if="item.name == 'mokuai73'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai74'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai75'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai76'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai77'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai78'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai79'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai80'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai81'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai82'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai83'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai84'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai85'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai86'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai87'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai88'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai89'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai90'" />
            <mokuai91 :item="item" :isImg="isImg" v-if="item.name == 'mokuai91'" />
            <mokuai92 :item="item" :isImg="isImg" v-if="item.name == 'mokuai92'" />
            <mokuai92 :item="item" :isImg="isImg" v-if="item.name == 'mokuai93'" />
            <mokuai94 :item="item" :isImg="isImg" v-if="item.name == 'mokuai94'" />
            <mokuai94 :item="item" :isImg="isImg" v-if="item.name == 'mokuai95'" />
            <mokuai96 :item="item" :isImg="isImg" v-if="item.name == 'mokuai96'" />
            <mokuai97 :item="item" :isImg="isImg" v-if="item.name == 'mokuai97'" />
            <mokuai97 :item="item" :isImg="isImg" v-if="item.name == 'mokuai98'" />
            <mokuai91 :item="item" :isImg="isImg" v-if="item.name == 'mokuai101'" />
            <mokuai102 :item="item" :isImg="isImg" v-if="item.name == 'mokuai102'" />
            <mokuai102 :item="item" :isImg="isImg" v-if="item.name == 'mokuai103'" />
            <mokuai104 :item="item" :isImg="isImg" v-if="item.name == 'mokuai104'" />
            <mokuai104 :item="item" :isImg="isImg" v-if="item.name == 'mokuai105'" />
            <mokuai38 :item="item" :isImg="isImg" v-if="item.name == 'mokuai106'" />
          </div>
        </div>
      </div>
      <div class="progressBox">
        <span class="progressBox_btnBox" @click="sacleBtnFun(2)">
          <a-icon type="minus" class="progressBox_btn" />
        </span>
        <div class="progressBox_bar">
          <!-- <progress-bar
          :value ="percent"
          :sliderMin="0"
          :sliderMax="100"
          @getScale="getScale"
          ref="progressBar"
            ></progress-bar> -->
          <a-slider :value="percent" :min="percentMin" :max="percentMax" @change="sliderChange" />
        </div>
        <span class="progressBox_bar_span"> {{percent}}%</span>
        <!-- <a-progress :percent="percent" class="progressBox_progress" strokeColor="#52c41a"/> -->
        <span class="progressBox_btnBox" @click="sacleBtnFun(1)">
          <a-icon type="plus" class="progressBox_btn" />
        </span>
      </div>
      <div class="SetBigScreetRight">
        <div class="SetBigScreetRightTop">
          <div class="SetBigScreetRightTopTab">
            <div>模块设置</div>
          </div>
        </div>
        <div v-if="isSet" class="SetBigScreetRightSet">
          <div v-if="nameLists.some(ele => ele == name)">
            <div class="SetBigScreetRightSetTiele">
              样式：
            </div>
            <div>
              <a-select v-model="name" @change="nameChange()" class="SetBigScreetRightSetInput" placeholder="请选择">
                <a-select-option v-for="item in nameList" :key="item.componentId" :value="item.name">
                  {{ item.componentName }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div v-if="titleLists.some(ele => ele == name)">
            <div class="SetBigScreetRightSetTiele">
              {{ name == 'mokuai65' ? '文本' : '标题' }}：
            </div>
            <div>
              <a-input class="SetBigScreetRightSetInput" placeholder="请输入标题" v-model="title" />
            </div>
          </div>
          <div v-if="titleImgTypeLists.some(ele => ele == name)">
            <div class="SetBigScreetRightSetTiele">
              标题样式：
            </div>
            <div>
              <a-select v-model="titleImgType" class="SetBigScreetRightSetInput" placeholder="请选择">
                <a-select-option v-for="item in titleImgTypeList" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div v-if="fontSizeLists.some(ele => ele == name)">
            <div class="SetBigScreetRightSetTiele">
              文字大小：
            </div>
            <div>
              <a-input-number class="SetBigScreetRightSetInput" v-model="fontSize" :min="1" />
            </div>
          </div>
          <div v-if="assessmentLists.some(ele => ele == name)">
            <div class="SetBigScreetRightSetTiele">
              考核项：
            </div>
            <div>
              <a-select v-model="assessmentIds" class="SetBigScreetRightSetInput" placeholder="请选择"
                :allowClear="unAllowClear" v-if="name=='mokuai46'">
                <a-select-option v-for="item in assessmentSimpleList" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
              <a-select v-model="assessmentIds" mode="multiple" class="SetBigScreetRightSetInput" placeholder="请选择"
                allowClear v-else>
                <a-select-option v-for="item in assessmentList" :key="item.id" :value="item.id"
                  :disabled="assessmentIds.length>=5">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div v-if="gradeLists.some(ele => ele == name)">
            <div class="SetBigScreetRightSetTiele">
              年级：
            </div>
            <div>
              <a-select v-model="gradeIds" mode="multiple" class="SetBigScreetRightSetInput" placeholder="请选择"
                allowClear>
                <a-select-option v-for="item in gradeList" :key="item.id" :value="item.id">
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div v-if="xuekeLists.some(ele => ele == name)">
            <div class="SetBigScreetRightSetTiele">
              学科：
            </div>
            <div>
              <a-select v-model="xuekeIds" mode="multiple" class="SetBigScreetRightSetInput" placeholder="请选择"
                allowClear>
                <a-select-option v-for="item in xuekeList" :key="item.id" :value="item.id" allowClear>
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </div>
          </div>
          <div v-if="stepLists.some(ele => ele == name)">
            <div class="SetBigScreetRightSetTiele">
              滚动速度：
            </div>
            <div>
              <a-input-number class="SetBigScreetRightSetInput" v-model="step" :min="1" />
            </div>
          </div>
          <div v-if="limitMoveNumLists.some(ele => ele == name)">
            <div class="SetBigScreetRightSetTiele">
              数据最小滚动的条数：
            </div>
            <div>
              <a-input-number class="SetBigScreetRightSetInput" v-model="limitMoveNum" :min="1" :precision='0' />
            </div>
          </div>
          <div v-if="useFakeDataLists.some(ele => ele == name)">
            <div class="SetBigScreetRightSetTiele">
              是否使用假数据：
            </div>
            <div>
              <a-checkbox class="SetBigScreetRightSetCkenk" :checked="useFakeData=='1'?true:false"
                @change="useFakeDataChange">
              </a-checkbox>
            </div>
          </div>
          <div class="uploadBox">
            <div class="filevideoUrlBox" v-if="imgLists.some(ele => ele == name)">
              <a-upload style="width: auto" name="avatar" list-type="picture-card" class="avatar-uploader"
                :show-upload-list="false" :action="fileUrl" :before-upload="beforeUpload" @change="handleChange">
                <div>
                  <a-icon :type="spinning ? 'plus' : 'plus'" />
                  <div class="ant-upload-text">上传图片</div>
                </div>
              </a-upload>
            </div>
            <div class="filevideoUrlBox" v-if="mp4Lists.some(ele => ele == name)">
              <a-upload name="file" :action="fileUrl" :file-list="fileList" @change="filevideoUrl($event)"
                id="filevideoUrlID">
                <a-button>
                  <a-icon type="upload" />上传视频
                </a-button>
              </a-upload>
              <a-spin tip="上传中..." class="filevideoUrlloding" v-show="filevideoUrlLoading" style="margin-left: 10px">
              </a-spin>
            </div>
            <div v-if="exelLists.some(ele => ele == name)">
              <a-button @click="downloadXls()" style="width: 5vw;height: 1.5vw;font-size: 0.7vw;margin-bottom: 0.5vw;">
                下载模板
              </a-button>
            </div>
            <div class="filevideoUrlBox" v-if="exelLists.some(ele => ele == name)">
              <a-upload name="file" :multiple="false" :action="name=='mokuai54' ? fileUrl3 : fileUrl2" @change="XlsChange">
                <a-button> <a-icon type="upload" />上传表格</a-button>
              </a-upload>
              <a-spin tip="上传中..." class="filevideoUrlloding" v-show="filevideoUrlLoading" style="margin-left: 10px">
              </a-spin>
            </div>
            <div class="uploadBox_imgBox">
              <div v-if="name == 'mokuai7'">
                <img :src="item" class="uploadBox_imgBox_img" v-for="(item, index) in imgSrcList" :key="index" alt="">
              </div>
              <img :src="imgSrc" class="uploadBox_imgBox_img" v-if="name == 'mokuai8' || name == 'mokuai30'" alt="">
              <video autoplay loop muted class="uploadBox_imgBox_img" v-if="name == 'mokuai9'">
                <source :src="videoSrc" type="video/mp4">
              </video>
            </div>
          </div>
          <a-button @click="isOK" type="primary" class="SetBigScreetRightSetBtn" v-if="name!='mokuai10'">
            确定
          </a-button>
        </div>
      </div>
    </div>
    <div class="SetBigScreetTemplate" v-if="list2.length==0">
      <div v-for="(item, index) in Template" :key="index">
        <div @click="loadData('0', item.bigScreenId)">
          <img :src="item.img ? item.img : 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1693470908376.jpg'">
          <div>{{ item.name }}</div>
        </div>
      </div>
    </div>
    <set-http ref="setHttp" @close="setHttpclose" />
  </div>
  <!-- </div> -->
</template>
<script>
  import html2canvas from "html2canvas"
  import moment from 'moment'
  import OSS from 'ali-oss'
  import $ from 'jquery'
  import api from '@/api/index'
  // import ProgressBar from '@/components/CustomModule/ProgressBar'
  import {
    mokuai1,
    mokuai2,
    mokuai3,
    mokuai5,
    mokuai10,
    mokuai11,
    mokuai12,
    mokuai13,
    mokuai14,
    mokuai15,
    mokuai16,
    mokuai17,
    mokuai18,
    mokuai19,
    mokuai20,
    mokuai21,
    mokuai22,
    mokuai23,
    bannerModel,
    mokuai27,
    mokuai29,
    mokuai34,
    mokuai35,
    mokuai38,
    mokuai36,
    mokuai37,
    mokuai40,
    mokuai41,
    mokuai42,
    mokuai45,
    mokuai50,
    mokuai61,
    mokuai58,
    mokuai59,
    mokuai65,
    mokuai71,
    mokuai72,
    mokuai73,
    mokuai91,
    mokuai92,
    mokuai94,
    mokuai96,
    mokuai97,
    mokuai102,
    mokuai104
  } from '@/components/index'
  import {
    modelsQuery,
    bigscreenUiPublish,
    xuekeSimpleList,
    gradeListSimple,
    assessmentSettingListSimple,
    bigScreenUiSsave,
    bigScreenUIList,
    bigScreenInSameModel,
    assessmentSettingSimple
  } from '@/api/other'
  import {
    Input,
    InputNumber,
    Select,
    Checkbox,
    Tooltip
  } from 'ant-design-vue';
  import SetHttp from '@/XyyPopups/SetHttp'
  export default {
    components: {
      mokuai1,
      mokuai2,
      mokuai3,
      mokuai5,
      mokuai10,
      mokuai11,
      mokuai12,
      mokuai13,
      mokuai14,
      mokuai15,
      mokuai16,
      mokuai17,
      mokuai18,
      mokuai19,
      mokuai20,
      mokuai21,
      mokuai22,
      mokuai23,
      bannerModel,
      mokuai27,
      mokuai29,
      mokuai34,
      mokuai35,
      mokuai38,
      mokuai36,
      mokuai37,
      mokuai40,
      mokuai41,
      mokuai42,
      mokuai45,
      mokuai50,
      mokuai58,
      mokuai59,
      mokuai65,
      mokuai71,
      mokuai72,
      mokuai73,
      mokuai91,
      mokuai92,
      mokuai94,
      mokuai96,
      mokuai97,
      mokuai102,
      mokuai104,
      'AInput': Input,
      'AInputNumber': InputNumber,
      'ASelect': Select,
      'ASelectOption': Select.Option,
      'ACheckbox': Checkbox,
      'ATooltip': Tooltip,
      SetHttp,
      mokuai61
      // ProgressBar
    },
    name: "SetBigScreet",
    data() {
      return {
        isAuxiliary: false,
        width: '',
        height: '',
        bg: '',
        bagBase64: '',
        isImg: false,
        // 学校id
        currentSubSchoolId: '',
        useType: '',
        title: '',
        componentId: '',
        tabType: '0',
        list2: [],
        Template: [],
        modelsList: [],
        id: '',
        name: '',
        nameList: [],
        isSet: false,
        fontSize: 16,
        fileUrl: 'https://onlinelesson-xueyoyo.oss-cn-beijing.aliyuncs.com',
        fileUrl2: `${api.BaseHost}/admin/excel/bigScreen/common/read `,
        fileUrl3: `${api.BaseHost}/admin/excel/teacher/keshi/read`,
        spinning: false,
        imgSrcList: [],
        imgSrc: '',
        videoSrc: '',
        fileList: [],
        filevideoUrlLoading: false,
        assessmentList: [],
        assessmentIds: [],
        assessmentNames: [],
        xuekeList: [],
        xuekeIds: [],
        xuekeNames: [],
        gradeList: [],
        gradeIds: [],
        gradeNames: [],
        titleImgTypeList: [
          {
            name: '样式1',
            id: '1'
          },
          {
            name: '样式2',
            id: '2'
          },
          {
            name: '样式3',
            id: '3'
          },
          {
            name: '样式4',
            id: '4'
          },
          {
            name: '样式5',
            id: '5'
          },
          {
            name: '样式6',
            id: '6'
          },
          {
            name: '样式7',
            id: '7'
          },
          {
            name: '样式8',
            id: '8'
          },
          {
            name: '样式9',
            id: '9'
          },
          {
            name: '样式10',
            id: '10'
          },
          {
            name: '样式11',
            id: '11'
          },
          {
            name: '样式12',
            id: '12'
          },
          {
            name: '样式13',
            id: '13'
          },
          {
            name: '样式14',
            id: '14'
          },
          {
            name: '样式15',
            id: '15'
          },
          {
            name: '样式16',
            id: '16'
          },
          {
            name: '样式17',
            id: '17'
          },
        ],
        titleImgType: '',
        useFakeData: '0',
        screenShot: '',
        domainId: '',
        submitType: '',
        bigScreenId: '',
        step: '',
        limitMoveNum: '',
        stepLists: [
          'mokuai13',
          'mokuai16',
          'mokuai17',
          'mokuai18',
          'mokuai19',
          'mokuai20',
          'mokuai22',
          'mokuai23',
          'mokuai27',
          'mokuai28',
          'mokuai32',
          'mokuai34',
          'mokuai35',
          'mokuai36',
          'mokuai37',
          'mokuai38',
          'mokuai39',
          'mokuai40',
          'mokuai41',
          'mokuai43',
          'mokuai44',
          'mokuai45',
          'mokuai46',
          'mokuai47',
          'mokuai48',
          'mokuai50',
          'mokuai51',
          'mokuai52',
          'mokuai54',
          'mokuai55',
          'mokuai56',
          'mokuai57',
          'mokuai58',
          'mokuai59',
          'mokuai60',
          'mokuai62',
          'mokuai64',
          'mokuai70',
          'mokuai71',
          'mokuai72',
          'mokuai73',
          'mokuai76',
          'mokuai79',
          'mokuai85',
          'mokuai86',
          'mokuai88',
          'mokuai74',
          'mokuai89',
          'mokuai90',
          'mokuai91',
          'mokuai101',
          'mokuai102',
          'mokuai103',
          'mokuai106'
        ],
        limitMoveNumLists: [
          'mokuai13',
          'mokuai16',
          'mokuai17',
          'mokuai18',
          'mokuai19',
          'mokuai20',
          'mokuai22',
          'mokuai23',
          'mokuai27',
          'mokuai28',
          'mokuai32',
          'mokuai34',
          'mokuai35',
          'mokuai36',
          'mokuai37',
          'mokuai38',
          'mokuai39',
          'mokuai40',
          'mokuai41',
          'mokuai43',
          'mokuai44',
          'mokuai45',
          'mokuai46',
          'mokuai47',
          'mokuai48',
          'mokuai50',
          'mokuai51',
          'mokuai52',
          'mokuai54',
          'mokuai55',
          'mokuai56',
          'mokuai57',
          'mokuai58',
          'mokuai59',
          'mokuai60',
          'mokuai62',
          'mokuai64',
          'mokuai70',
          'mokuai72',
          'mokuai73',
          'mokuai76',
          'mokuai79',
          'mokuai85',
          'mokuai86',
          'mokuai88',
          'mokuai74',
          'mokuai89',
          'mokuai90',
          'mokuai91',
          'mokuai101',
          'mokuai102',
          'mokuai103',
          'mokuai106'
        ],
        nameLists: [
          'mokuai1',
          'mokuai2',
          'mokuai8',
          'mokuai13',
          'mokuai16',
          'mokuai17',
          'mokuai18',
          'mokuai19',
          'mokuai20',
          'mokuai22',
          'mokuai23',
          'mokuai25',
          'mokuai26',
          'mokuai27',
          'mokuai28',
          'mokuai30',
          'mokuai31',
          'mokuai33',
          'mokuai34',
          'mokuai35',
          'mokuai36',
          'mokuai37',
          'mokuai38',
          'mokuai39',
          'mokuai40',
          'mokuai41',
          'mokuai43',
          'mokuai44',
          'mokuai46',
          'mokuai48',
          'mokuai50',
          'mokuai51',
          'mokuai53',
          'mokuai57',
          'mokuai58',
          'mokuai59',
          'mokuai60',
          'mokuai66',
          'mokuai67',
          'mokuai68',
          'mokuai69',
          'mokuai70',
          'mokuai71',
          'mokuai73',
          'mokuai77',
          'mokuai83',
          'mokuai78',
          'mokuai84',
          'mokuai79',
          'mokuai86',
          'mokuai74',
          'mokuai87',
          'mokuai76',
          'mokuai88',
          'mokuai99',
          'mokuai100'
        ],
        titleLists: [
          'mokuai1',
          'mokuai11',
          'mokuai13',
          'mokuai14',
          'mokuai16',
          'mokuai17',
          'mokuai19',
          'mokuai21',
          'mokuai22',
          'mokuai23',
          'mokuai25',
          'mokuai29',
          'mokuai31',
          'mokuai33',
          'mokuai34',
          'mokuai38',
          'mokuai40',
          'mokuai42',
          'mokuai43',
          'mokuai44',
          'mokuai47',
          'mokuai50',
          'mokuai51',
          'mokuai53',
          'mokuai55',
          'mokuai57',
          'mokuai58',
          'mokuai59',
          'mokuai65',
          'mokuai66',
          'mokuai67',
          'mokuai68',
          'mokuai72',
          'mokuai73',
          'mokuai74',
          'mokuai76',
          'mokuai77',
          'mokuai78',
          'mokuai79',
          'mokuai80',
          'mokuai81',
          'mokuai82',
          'mokuai83',
          'mokuai84',
          'mokuai85',
          'mokuai86',
          'mokuai87',
          'mokuai88',
          'mokuai91',
          'mokuai92',
          'mokuai93',
          'mokuai97',
          'mokuai98',
          'mokuai99',
          'mokuai101',
          'mokuai102',
          'mokuai103',
          'mokuai104',
          'mokuai105'
        ],
        titleImgTypeLists: [
          'mokuai11',
          'mokuai14',
          'mokuai17',
          'mokuai19',
          'mokuai21',
          'mokuai22',
          'mokuai23',
          'mokuai29',
          'mokuai34',
          'mokuai38',
          'mokuai43',
          'mokuai44',
          'mokuai47',
          'mokuai51'
        ],
        fontSizeLists: [
          'mokuai1',
          'mokuai3',
          'mokuai5',
          'mokuai11',
          'mokuai12',
          'mokuai13',
          'mokuai14',
          'mokuai15',
          'mokuai16',
          'mokuai17',
          'mokuai18',
          'mokuai19',
          'mokuai20',
          'mokuai21',
          'mokuai22',
          'mokuai23',
          'mokuai25',
          'mokuai27',
          'mokuai28',
          'mokuai29',
          'mokuai31',
          'mokuai32',
          'mokuai33',
          'mokuai34',
          'mokuai35',
          'mokuai38',
          'mokuai39',
          'mokuai36',
          'mokuai37',
          'mokuai40',
          'mokuai41',
          'mokuai42',
          'mokuai43',
          'mokuai44',
          'mokuai45',
          'mokuai46',
          'mokuai47',
          'mokuai48',
          'mokuai50',
          'mokuai51',
          'mokuai52',
          'mokuai53',
          'mokuai54',
          'mokuai55',
          'mokuai56',
          'mokuai57',
          'mokuai61',
          'mokuai64',
          'mokuai58',
          'mokuai59',
          'mokuai60',
          'mokuai61',
          'mokuai62',
          'mokuai63',
          'mokuai65',
          'mokuai66',
          'mokuai67',
          'mokuai68',
          'mokuai70',
          'mokuai71',
          'mokuai72',
          'mokuai73',
          'mokuai74',
          'mokuai76',
          'mokuai77',
          'mokuai78',
          'mokuai79',
          'mokuai80',
          'mokuai81',
          'mokuai82',
          'mokuai83',
          'mokuai84',
          'mokuai85',
          'mokuai86',
          'mokuai87',
          'mokuai88',
          'mokuai89',
          'mokuai90',
          'mokuai91',
          'mokuai92',
          'mokuai93',
          'mokuai94',
          'mokuai95',
          'mokuai96',
          'mokuai97',
          'mokuai98',
          'mokuai99',
          'mokuai101',
          'mokuai102',
          'mokuai103',
          'mokuai104',
          'mokuai105',
          'mokuai106'
        ],
        assessmentLists: [
          'mokuai18',
          'mokuai35',
          'mokuai41',
          'mokuai45',
          'mokuai46'
        ],
        gradeLists: [
          'mokuai11',
          'mokuai12',
          'mokuai13',
          'mokuai14',
          'mokuai16',
          'mokuai17',
          'mokuai18',
          'mokuai19',
          'mokuai20',
          'mokuai21',
          'mokuai22',
          'mokuai23',
          'mokuai27',
          'mokuai28',
          'mokuai34',
          'mokuai35',
          'mokuai38',
          'mokuai39',
          'mokuai36',
          'mokuai37',
          'mokuai40',
          'mokuai41',
          'mokuai42',
          'mokuai43',
          'mokuai44',
          'mokuai45',
          'mokuai46',
          'mokuai47',
          'mokuai51',
          'mokuai52',
          'mokuai55',
          'mokuai56',
          'mokuai57',
          'mokuai60',
          'mokuai62',
          'mokuai63',
          'mokuai70',
          'mokuai72'
        ],
        xuekeLists: [
          'mokuai11',
          'mokuai12',
          'mokuai13',
          'mokuai14',
          'mokuai16',
          'mokuai22',
          'mokuai23',
          'mokuai40',
          'mokuai42',
          'mokuai51',
          'mokuai52',
          'mokuai55',
          'mokuai56',
          'mokuai57',
          'mokuai60',
          'mokuai62',
          'mokuai63',
          'mokuai72'
        ],
        useFakeDataLists: [
          'mokuai11',
          'mokuai12',
          'mokuai13',
          'mokuai14',
          'mokuai15',
          'mokuai16',
          'mokuai17',
          'mokuai18',
          'mokuai19',
          'mokuai20',
          'mokuai21',
          'mokuai22',
          'mokuai23',
          'mokuai27',
          'mokuai28',
          'mokuai34',
          'mokuai35',
          'mokuai38',
          'mokuai39',
          'mokuai36',
          'mokuai37',
          'mokuai40',
          'mokuai41',
          'mokuai42',
          'mokuai43',
          'mokuai44',
          'mokuai45',
          'mokuai46',
          'mokuai48',
          'mokuai47',
          'mokuai51',
          'mokuai52',
          'mokuai54',
          'mokuai55',
          'mokuai56',
          'mokuai57',
          'mokuai61',
          'mokuai64',
          'mokuai60',
          'mokuai61',
          'mokuai62',
          'mokuai63',
          'mokuai70',
          'mokuai72',
          'mokuai71',
          'mokuai73',
          'mokuai74',
          'mokuai75',
          'mokuai76',
          'mokuai77',
          'mokuai78',
          'mokuai79',
          'mokuai80',
          'mokuai81',
          'mokuai82',
          'mokuai83',
          'mokuai84',
          'mokuai85',
          'mokuai86',
          'mokuai87',
          'mokuai88',
          'mokuai89',
          'mokuai90',
          'mokuai91',
          'mokuai92',
          'mokuai93',
          'mokuai96',
          'mokuai97',
          'mokuai98',
          'mokuai101',
          'mokuai102',
          'mokuai103',
          'mokuai104',
          'mokuai105',
          'mokuai106'
        ],
        imgLists: [
          'mokuai7',
          'mokuai8',
          'mokuai30'
        ],
        mp4Lists: [
          'mokuai9'
        ],
        exelLists: [
          'mokuai32',
          'mokuai50',
          'mokuai54',
          'mokuai58',
          'mokuai59'
        ],
        transNum: 0.01,
        oriTransNum: 1,
        percent: 100,
        percentMin: 1,
        percentMax: 100,
        assessmentSimpleList: [],
        unAllowClear: false,
        bclassId: ''
      }
    },
    watch: {
    },
    methods: {
      clear () {
        this.list2 = []
        this.isSet = false
      },
      downloadXls() {
        if (this.name == 'mokuai54') {
          window.open('https://www.xueyoyo.com.cn/admin/excel/teacher/keshi/export?subSchoolId=' + this.currentSubSchoolId)
        } else {
          window.open('https://tiku-imgs.oss-cn-beijing.aliyuncs.com/bigScreen/excel/big_screen_excel_month_template.xls')
        }
      },
      goBack() {
        this.$router.go(-1)
      },
      useFakeDataChange(val) {
        this.useFakeData = val.target.checked ? '1' : '0'
      },
      nameChange() {
        for (let i = 0; i < this.nameList.length; i++) {
          if (this.name == this.nameList[i].name) {
            if (this.name != 'mokuai8' && this.name != 'mokuai30') {
              this.imgSrc = this.nameList[i].img
            }
            this.videoSrc = this.nameList[i].mp4
          }
        }
      },
      getName() {
        bigScreenInSameModel(
          {
            componentId: this.componentId
          },
          (resp) => {
            const data = resp.data
            this.nameList = data
          },
          (err) => {
            console.log('err', err)
          }
        )
      },
      getXuekeSimpleList() {
        xuekeSimpleList(
          {
            subSchoolId: this.currentSubSchoolId
          },
          (resp) => {
            const data = resp.data
            if (this.$route.query.bigScreenIds) {
              this.loadData('0', this.$route.query.bigScreenIds)
            } else {
              this.loadData(this.currentSubSchoolId, this.bigScreenId)
            }
            this.xuekeList = data
          },
          (err) => {
            console.log('err', err)
          }
        )
      },
      getGradeListSimple() {
        gradeListSimple(
          {
            subSchoolId: this.currentSubSchoolId
          },
          (resp) => {
            const data = resp.data
            this.gradeList = data
          },
          (err) => {
            console.log('err', err)
          }
        )
      },
      getAssessmentSettingListSimple() {
        assessmentSettingListSimple(
          {
            subSchoolId: this.currentSubSchoolId
          },
          (resp) => {
            const data = resp.data
            this.assessmentList = data
          },
          (err) => {
            console.log('err', err)
          }
        )
      },
      getAssessmentListSimple() {
        this.assessmentSimpleList = []
        assessmentSettingSimple(
          {
            subSchoolId: this.currentSubSchoolId
          },
          (resp) => {
            const data = resp.data
            for (let i = 0; i < data.length; i++) {
              const e = data[i];
              if (e.name !== '宿管日志') {
                this.assessmentSimpleList.push(e)
              }
            }
          },
          (err) => {
            console.log('err', err)
          }
        )
      },
      tabClick(val) {
        this.tabType = val
        this.getModelsQuery()
      },
      getModelsQuery() {
        this.modelsList = []
        modelsQuery(
          {
            subSchoolId: this.currentSubSchoolId,
            mType: this.tabType,
            useType: this.useType
          },
          (resp) => {
            const temp = resp.data
            this.modelsList = temp
          },
          (err) => {
            console.log('err', err)
          }
        )
      },
      save() {
        bigScreenUiSsave(
          {
            bigScreenId: this.bigScreenId,
            bsJson: JSON.stringify(this.list2),
            screenShot: this.screenShot,
            bgHeight: this.height,
            bgWidth: this.width,
            bgImg: this.bg,
            useType: this.useType
          },
          (resp) => {
            console.log('保存成功！', resp)
            if (resp.code === 0) {
              this.$message.success('保存成功！');
            } else {
              this.$message.error(resp.errMsg);
            }
          },
          (err) => {
            this.$message.error(err);
            console.log('err', err)
          }
        )
      },
      publishFun() {
        if (!this.domainId || this.domainId === 0) {
          this.$refs.setHttp.showModel(this.currentSubSchoolId, this.useType)
          return
        }
        bigscreenUiPublish(
          {
            bigScreenId: this.bigScreenId,
            domainId: this.domainId,
            bsJson: JSON.stringify(this.list2),
            screenShot: this.screenShot,
            bgHeight: this.height,
            bgWidth: this.width,
            bgImg: this.bg,
            useType: this.useType
          },
          (resp) => {
            console.log('发布成功！', resp)
            if (resp.code === 0) {
              this.$message.success('发布成功！');
            } else {
              this.$message.error(resp.errMsg);
            }
          },
          (err) => {
            console.log('err', err)
            this.$message.error(err);
          }
        )
      },
      goPreview() {
        localStorage.setItem('dataList', JSON.stringify(this.list2))
        localStorage.setItem('width', this.width)
        localStorage.setItem('height', this.height)
        localStorage.setItem('bg', this.bg)
        localStorage.setItem('cType', this.useType)
        const href = this.$router.resolve({
          name: 'PreviewBigScreet',
          query: {
            currentSubSchoolId: this.currentSubSchoolId
          }
        })
        window.open(href.href, '_blank')
      },
      dragend(e) {
        if (e.layerX > 200) {
          for (let i = 0; i < this.modelsList.length; i++) {
            for (let k = 0; k < this.modelsList[i].components.length; k++) {
              if (e.srcElement.id == this.modelsList[i].components[k].componentId) {
                this.mousedown1(e.srcElement.id, e)
              }
            }
          }
        }
      },
      mousedown(e) {
        if (e) {
          for (let i = 0; i < this.list2.length; i++) {
            if (e.srcElement.id == this.list2[i].id) {
              this.mousedown2(e.srcElement.id, e)
            }
          }
          if (e.target.className == 'itemBoxBottom') {
            this.mousedownBottom(e)
          }
          if (e.target.className == 'itemBoxRight') {
            this.mousedownRight(e)
          }
          if (e.target.className == 'itemBoxRightBottom') {
            this.mousedownRightBottom(e)
          }
        }
      },

      mousedown1(id, event) {
        const that = this
        for (let i = 0; i < this.modelsList.length; i++) {
          for (let k = 0; k < this.modelsList[i].components.length; k++) {
            if (id == this.modelsList[i].components[k].componentId) {
              var name = this.modelsList[i].components[k].name
              var classs = this.modelsList[i].components[k].style
              var width = this.modelsList[i].components[k].width == -1 ? '' : this.modelsList[i].components[k].width
              var height = this.modelsList[i].components[k].height == -1 ? '' : this.modelsList[i].components[k].height
              var title = this.modelsList[i].components[k].componentName
              var mp4 = this.modelsList[i].components[k].mp4
              var img = this.modelsList[i].components[k].img
              var fixedRatio = this.modelsList[i].components[k].fixedRatio
              var componentId = this.modelsList[i].components[k].componentId
            }
          }
        }
        const innerX = event.clientX - 200
        const innerY = event.clientY - 100
        const date = Date.now()
        var assessmentIds = []
        var assessmentNames = []
        if(name === 'mokuai46') {
          for (let i = 0; i < this.assessmentSimpleList.length; i++) {
            if (i === 0) {
              assessmentIds = this.assessmentSimpleList[i].id
              assessmentNames = this.assessmentSimpleList[i].name
            }
          }
        }else{
          for (let i = 0; i < this.assessmentList.length; i++) {
            if (i < 5) {
              assessmentIds.push(this.assessmentList[i].id)
              assessmentNames.push(this.assessmentList[i].name)
            }
          }
        }
        that.list2.push({
          name: name,
          id: `${name}${date}`,
          style: classs,
          date: date,
          top: innerY,
          left: innerX,
          width: width,
          height: height,
          aspectRatio: height / width,
          title: title,
          fontSize: 16,
          mp4: mp4,
          img: img,
          imgList: [],
          fixedRatio: fixedRatio,
          assessmentIds: that.assessmentLists.some(ele => ele == name) ? assessmentIds : [],
          assessmentNames: that.assessmentLists.some(ele => ele == name) ? assessmentNames : [],
          xuekeIds: that.xuekeList[0] ? [that.xuekeList[0].id] : [],
          xuekeNames: that.xuekeList[0] ? [that.xuekeList[0].name] : [],
          gradeIds: that.gradeList[0] ? [that.gradeList[0].id] : [],
          gradeNames: that.gradeList[0] ? [that.gradeList[0].name] : [],
          useFakeData: '0',
          componentId: componentId,
          titleImgType: '1',
          exel: [],
          step: 1,
          limitMoveNum: 6,
        })
        $(document).mouseup(function () {
          $(document).unbind('mousedown')
        })

      },
      mousedown2(id, event) {
        const that = this
        const box = $(`#${id}`)[0]
        const offsetLeft = box.offsetLeft
        const offsetTop = box.offsetTop
        const innerX = event.clientX - offsetLeft
        const innerY = event.clientY - offsetTop
        $(document).mousemove(function (e) {
          box.classList.add('move')
          box.style.left = e.clientX - innerX + 'px'
          box.style.top = e.clientY - innerY + 'px'
          for (let i = 0; i < that.list2.length; i++) {
            if (that.list2[i].id == id) {
              that.list2[i].left = e.clientX - innerX
              that.list2[i].top = e.clientY - innerY
            }
          }
        })
        $(document).mouseup(function () {
          $(document).unbind('mousemove').unbind('mousedown')
          box.classList.remove('move')
        })
      },
      mousedownBottom(event) {
        const that = this
        const box = $(`#${event.srcElement.offsetParent.offsetParent.id}`)[0]
        const offsetHeight = box.offsetHeight
        $(document).mousemove(function (e) {
          box.classList.add('move')
          const addHeight = (e.clientY - event.clientY)
          box.style.height = addHeight + offsetHeight + 'px'
          for (let i = 0; i < that.list2.length; i++) {
            if (that.list2[i].id == event.srcElement.offsetParent.offsetParent.id) {
              that.list2[i].height = addHeight + offsetHeight
            }
          }
        })
        $(document).mouseup(function () {
          $(document).unbind('mousemove').unbind('mousedown')
          box.classList.remove('move')
        })
      },
      mousedownRight(event) {
        const that = this
        const box = $(`#${event.srcElement.offsetParent.offsetParent.id}`)[0]
        const offsetWidth = box.offsetWidth
        $(document).mousemove(function (e) {
          box.classList.add('move')
          const addWidth = (e.clientX - event.clientX)
          box.style.width = addWidth + offsetWidth + 'px'
          for (let i = 0; i < that.list2.length; i++) {
            if (that.list2[i].id == event.srcElement.offsetParent.offsetParent.id) {
              that.list2[i].width = addWidth + offsetWidth
            }
          }
        })
        $(document).mouseup(function () {
          $(document).unbind('mousemove').unbind('mousedown')
          box.classList.remove('move')
        })
      },
      mousedownRightBottom(event) {
        const that = this
        const box = $(`#${event.srcElement.offsetParent.offsetParent.id}`)[0]
        const offsetWidth = box.offsetWidth
        $(document).mousemove(function (e) {
          box.classList.add('move')
          for (let i = 0; i < that.list2.length; i++) {
            if (that.list2[i].id == event.srcElement.offsetParent.offsetParent.id) {
              const addWidth = (e.clientX - event.clientX)
              box.style.width = `${addWidth + offsetWidth}px`
              box.style.height = `${(addWidth + offsetWidth) * that.list2[i].aspectRatio}px`
              that.list2[i].width = addWidth + offsetWidth
              that.list2[i].height = (addWidth + offsetWidth) * that.list2[i].aspectRatio
            }
          }
        })
        $(document).mouseup(function () {
          $(document).unbind('mousemove').unbind('mousedown')
          box.classList.remove('move')
        })
      },
      click(e) {
        if (e.target.className == 'itemBoxDel') {
          this.isSet = false
          this.clickDel(e)
        }
        for (let i = 0; i < this.list2.length; i++) {
          if (e.srcElement.id == this.list2[i].id) {
            this.isSet = false
            this.clickSet(e.srcElement.id)
          }
        }
      },
      clickDel(event) {
        const that = this
        for (let i = 0; i < that.list2.length; i++) {
          if (that.list2[i].id == event.srcElement.offsetParent.offsetParent.id) {
            // console.log(this.list2)
            that.list2.splice(i, 1)
            console.log(this.list2)
          }
        }
      },
      clickSet(id) {
        const that = this
        for (let i = 0; i < that.list2.length; i++) {
          if (that.list2[i].id == id) {
            that.id = that.list2[i].id
            that.name = that.list2[i].name
            if (that.assessmentLists.some(ele => ele == that.name)) {
              this.assessmentIds = that.list2[i].assessmentIds
              this.assessmentNames = that.list2[i].assessmentNames
            }
            that.componentId = that.list2[i].componentId
            that.title = that.list2[i].title
            that.fontSize = that.list2[i].fontSize
            that.step = that.list2[i].step
            that.limitMoveNum = that.list2[i].limitMoveNum
            that.xuekeIds = that.list2[i].xuekeIds
            that.xuekeNames = that.list2[i].xuekeNames
            that.gradeIds = that.list2[i].gradeIds
            that.gradeNames = that.list2[i].gradeNames
            that.useFakeData = that.list2[i].useFakeData
            that.isSet = true
            that.imgSrcList = that.list2[i].imgList ? that.list2[i].imgList : []
            that.imgSrc = that.list2[i].img
            that.videoSrc = that.list2[i].mp4
            that.titleImgType = that.list2[i].titleImgType
            that.exel = that.list2[i].exel
            that.getName()
          }
        }
      },
      isOK() {
        console.log(this.name)
        const that = this
        if (this.titleLists.some(ele => ele == that.name)) {
          if (!this.title) {
            this.$message.warning('请输入标题')
            return
          }
        }
        if (this.fontSizeLists.some(ele => ele == that.name)) {
          if (!this.fontSize) {
            this.$message.warning('请输入文字大小')
            return
          }
        }
        if (this.assessmentLists.some(ele => ele == that.name)) {
          if (this.assessmentIds.length == 0) {
            this.$message.warning('请选择考核项')
            return
          }
        }
        if (this.gradeLists.some(ele => ele == that.name)) {
          if (this.gradeIds == 0) {
            this.$message.warning('请选择年级')
            return
          }
        }
        if (this.xuekeLists.some(ele => ele == that.name)) {
          if (this.xuekeIds.length == 0) {
            this.$message.warning('请选择学科')
            return
          }
        }
        if (that.name == 'mokuai7') {
          if (that.imgSrcList == 0) {
            that.$message.warning('请选择图片')
            return
          }
        }
        if (this.exelLists.some(ele => ele == that.name)) {
          if (that.exel == [] && that.name != 'mokuai54') {
            that.$message.warning('请上传表格')
            return
          }
        }
        if (this.stepLists.some(ele => ele == that.name)) {
          if (!that.step) {
            that.$message.warning('请输入滚动速度')
            return
          }
        }
        if (this.limitMoveNumLists.some(ele => ele == that.name)) {
          if (!that.limitMoveNum) {
            that.$message.warning('请输入数据最小滚动的条数')
            return
          }
        }
        if ($(`#${that.id}`).find('.title')[0]) {
          $(`#${that.id}`).find('.title')[0].innerHTML = that.title
        }
        $(`#${that.id}`)[0].style.fontSize = that.fontSize + 'px'
        for (let i = 0; i < that.list2.length; i++) {
          if (that.list2[i].id == that.id) {
            that.list2[i].name = that.name
            that.list2[i].title = that.title
            that.list2[i].fontSize = that.fontSize
            that.list2[i].step = that.step
            that.list2[i].limitMoveNum = that.limitMoveNum
            if (that.name == 'mokuai7') {
              that.list2[i].imgList = that.imgSrcList
            }
            that.list2[i].img = that.imgSrc
            that.list2[i].mp4 = that.videoSrc
            that.list2[i].useFakeData = that.useFakeData
            that.list2[i].xuekeIds = that.xuekeIds
            that.list2[i].titleImgType = that.titleImgType
            that.list2[i].exel = that.exel
            that.xuekeNames = []
            that.xuekeList.forEach((item, index) => {
              that.xuekeIds.forEach((items, indexs) => {
                if (item.id == items) {
                  const name = item.name
                  that.xuekeNames.push(name)
                }
              })
            })
            that.list2[i].xuekeNames = that.xuekeNames
            that.list2[i].gradeIds = that.gradeIds
            that.gradeNames = []
            that.gradeIds.forEach((item, index) => {
              that.gradeList.forEach((items, indexs) => {
                if (items.id == item) {
                  that.gradeNames.push(items.name)
                }
              })
            })
            that.list2[i].gradeNames = that.gradeNames
            if (that.assessmentLists.some(ele => ele == that.name)) {
              that.assessmentNames = []
              that.list2[i].assessmentIds = that.assessmentIds
              if (that.name == 'mokuai46') {
                that.assessmentList.forEach((items, indexs) => {
                  if (that.assessmentIds == items.id) {
                    that.assessmentNames.push(items.name)
                  }
                })
              } else {
                that.assessmentIds.forEach((item, index) => {
                  that.assessmentList.forEach((items, indexs) => {
                    if (item == items.id) {
                      that.assessmentNames.push(items.name)
                    }
                  })
                })
              }
              that.list2[i].assessmentNames = that.assessmentNames
            }
            that.isSet = false
          }
        }
      },
      handleChange(info) {
        if (info.file.status === 'uploading') {
          this.spinning = true
          return
        }
        if (info.file.status === 'done') {
          // Get this url from response in real world.
        }
      },
      XlsChange(info) {
        const suffixIndex = info.file.name.lastIndexOf('.')
        const suffix = info.file.name.substring(suffixIndex, info.file.name.length)
        const str = '.xlsx, .Xlsx, .XLSX, .xls, .Xls, .XLS'
        if (str.indexOf(suffix) < 0) {
          this.$message.warning(`请上传xlsx或xls格式的表格`)
          return
        }
        if (info.file.status !== 'uploading') {
          console.log(info.file.response)
          if (info.file.response.code === 0) {
            this.$message.success(`${info.file.name} 文件上传成功`)
          } else {
            this.$message.error(`${info.file.response.errMsg}`)
          }
        }
        if (info.file.status === 'error') {
          this.filevideoUrlLoading = true
        }
        if (info.file.status === 'done') {
          this.exel = info.file.response.data
          if (this.name == 'mokuai50' || this.name == 'mokuai58' || this.name == 'mokuai59') {
            for (let i = 0; i < this.exel.length; i++) {
              if (i != 0) {
                let exel = this.exel[i].split(',')
                if (exel[exel.length].indexOf('%') === -1) {
                  this.$message.warning('上传的表格不符合格式')
                  this.exel = []
                  break
                }
              }
            }
          }
          this.filevideoUrlLoading = false
        }
      },
      filevideoUrl(info) {
        console.log(info)
        if (info.file.size > 100 * 1024 * 1024) {
          // this.$message.warning(`此视频已超过200M`, 6)
          this.$message.warning(`此视频大小为 ${info.file.size}M,上传视频不得大于100M`, 6)
          return
        }
        const _this = this
        this.videoUrl = ''
        _this.loading = true
        var client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: 'LTAI4GGi1sAJMc6P5sLwouz4',
          accessKeySecret: 'I5FyAcjMVmBPIGu5Iwbw6xq3Y5KBEE',
          bucket: 'zhxz-xueyoyo'
        })
        if (info.file.status === 'error') {
          this.filevideoUrlLoading = true
        }
        //  截取后缀
        const suffixIndex = info.file.name.lastIndexOf('.')
        const suffix = info.file.name.substring(suffixIndex, info.file.name.length)
        const str = '.mp4, .MP4, .Mp4'
        if (str.indexOf(suffix) < 0) {
          this.$message.warning(`请上传mp4格式的视频`, 6)
          return
        }
        _this.fileList = [...info.fileList]
        if (info.file.status !== 'uploading') {
          //  把文件名转化为时间戳
          this.millisecond = moment().valueOf()
          const FileName = this.millisecond + suffix
          client
            .multipartUpload(FileName, info.file.originFileObj)
            .then(function (res) {
              _this.loading = false
              let str = res.res.requestUrls[0]
              str = str.replace(/http:/g, 'https:')
              _this.videoSrc = str.split('?')[0]
              _this.fileList = []
              _this.$message.success(`${info.file.name} 文件上传成功`)
              _this.filevideoUrlLoading = false
              _this.videoUrlShow = true
            })
            .catch((err) => {
              console.log(err)
            })
        } else {
          // this.$message.error(`上传失败`)
        }
        // }
      },
      beforeUpload(file) {
        const that = this
        const info = file
        if (that.imgSrcList.length > 4) {
          this.$message.error('最多只能上传5张图')
          return
        }
        //   this.$OSSFun(this, file, 5)
        var client = new OSS({
          region: 'oss-cn-beijing',
          accessKeyId: 'LTAI4GGi1sAJMc6P5sLwouz4',
          accessKeySecret: 'I5FyAcjMVmBPIGu5Iwbw6xq3Y5KBEE',
          bucket: 'zhxz-xueyoyo'
        })
        //  截取后缀
        const suffixIndex = info.name.lastIndexOf('.')
        const suffix = info.name.substring(suffixIndex, info.name.length)
        // if (info.status !== 'uploading') {
        //  把文件名转化为时间戳
        this.millisecond = moment().valueOf()
        const FileName = 'Image/' + this.millisecond + suffix
        client
          .multipartUpload(FileName, info)
          .then(function (res) {
            that.spinning = false
            let str = res.res.requestUrls[0]
            str = str.replace(/http:/g, 'https:')
            // that.videoUrl = str.split('?')[0]
            console.log(str.split('?')[0])
            if (that.name == 'mokuai7') {
              that.imgSrcList.push(str.split('?')[0])
            } else if (that.name == 'mokuai8' || that.name == 'mokuai30') {
              that.imgSrc = str.split('?')[0]
            }
            // that.addImg(str.split('?')[0])
            // return str.split('?')[0]
          })
          .catch((err) => {
            console.log(err)
          })
      },
      photoCompress(base64Url, type) {
        const that = this
        var imgAttr = { quality: 0.2, type: 'img/jpeg' };
        // var base64Url = 'https://onlinelesson-img.oss-cn-beijing.aliyuncs.com/Image/1693562742237.png'
        var img = new Image();
        img.src = base64Url;
        img.setAttribute('crossOrigin', 'Anonymous')
        img.onload = function () {
          // var that = this;// 默认按比例压缩
          var w = 275
          var h = 155// 宽高比例
          var scale = w / h
          // 默认图片质量为8.7
          var quality = 0.7;//生成canvasvar 
          var canvas = document.createElement('canvas');
          var ctx = canvas.getContext('2d') // 创建属性节点
          var anw = document.createAttribute("width");
          anw.nodeValue = w + 'px';
          canvas.setAttributeNode(anw);
          var anh = document.createAttribute("height");
          anh.nodeValue = h + 'px';
          canvas.setAttributeNode(anh);
          ctx.drawImage(img, 0, 0, w, h);// 图像质量《8-1)if(imgAttr.quality && imgAttr.guality <= 1 8& imgAttr.quality > o)(quality = imgAttr.quality;
          // 重绘后的图片类型
          var imgType = imgAttr.type || 'image/jpeg';
          // quality值越小，所绘制出的图像越模糊
          var base64 = canvas.toDataURL(imgType, quality)
          // 回调函数返回base64的值
          console.log(base64)
          that.OSSbase64(base64, type)
          // callback(base64);
        }
      },
      snapshoot(type) {
        const that = this
        that.isImg = true
        this.$confirm({
          title: '提示',
          content: h => <div style="color:red;">是否确定操作？</div>,
          onOk() {
            console.log('OK');
            const div = $("#screen")[0];
            html2canvas(div).then(function (canvas) {
              //  console.log(canvas) 275*155
              // canvas.width = 275
              // canvas.height = 155
              console.log(canvas.toDataURL("image/png"))
              let imgSrc = canvas.toDataURL("image/png", 0.3);
              that.photoCompress(imgSrc, type)
            })
          },
          onCancel() {
            console.log('Cancel');
            that.isImg = false
          },
        })
      },
      toBlob(urlData, fileType) {
        const bytes = window.atob(urlData)
        let n = bytes.length
        const u8arr = new Uint8Array(n)
        while (n--) {
          u8arr[n] = bytes.charCodeAt(n)
        }
        return new Blob([u8arr], { type: fileType })
      },
      OSSbase64(info, type) {
        const that = this
        this.spinning = true
        // 图像数据 (e.g. data:image/png;base64,iVBOR...yssDuN70DiAAAAABJRU5ErkJggg==)
        const urlData = info
        const base64 = urlData.split(',').pop()
        const fileType = urlData.split(';').shift().split(':').pop()

        // base64转blob
        const blob = this.toBlob(base64, fileType)

        // blob转arrayBuffer
        const reader = new FileReader()
        reader.readAsArrayBuffer(blob)
        reader.onload = function (event) {
          // 配置
          var client = new OSS({
            region: 'oss-cn-beijing',
            accessKeyId: 'LTAI4GGi1sAJMc6P5sLwouz4',
            accessKeySecret: 'I5FyAcjMVmBPIGu5Iwbw6xq3Y5KBEE',
            bucket: 'onlinelesson-img'
          })

          // 文件名
          const objectKey = `/Image/${new Date().getTime()}.${fileType.split('/').pop()}`

          // arrayBuffer转Buffer
          const buffer = new OSS.Buffer(event.target.result)

          // 上传
          client
            .put(objectKey, buffer)
            .then(function (res) {
              console.log(res)
              let str = res.res.requestUrls[0]
              str = str.replace(/http:/g, 'https:')
              that.screenShot = str
              console.log(str)
              that.spinning = false
              that.submitType = type
              if (type === 1) {
                that.publishFun()
              } else {
                that.save()
              }
              that.isImg = false
              // that.uploadStuAnswerByTeacherFun(str)
            })
            .catch(function (err) {
              console.log(err)
              that.spinning = false
            })
        }
      },
      setHttpclose(domainId) {
        this.domainId = domainId
        this.publishFun()
      },
      loadData(currentSubSchoolId, bigScreenId) {
        const that = this
        bigScreenUIList(
          {
            subSchoolId: currentSubSchoolId,
            bigScreenId: bigScreenId,
          useType: this.useType
          },
          (resp) => {
            console.log(resp);
            if (resp.code === 0) {
              if(resp.data.length === 0){
                this.height = 1080
              this.width = 1920
              this.bg = 'https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1705392530381.jpg'
                that.$getBase64('https://zhxz-xueyoyo.oss-cn-beijing.aliyuncs.com/Image/1705392530381.jpg', (dataURL) => {
                // console.log("dataURL=>", dataURL);
                that.bagBase64 = dataURL;
              })
                return
              }
              this.height = resp.data[0].bgHeight
              this.width = resp.data[0].bgWidth
              this.bg = resp.data[0].bgImg
              console.log(resp.data[0].bclassId)
              // this.bclassId = resp.data[0].bclassId
              // localStorage.setItem('bclassId', resp.data[0].bclassId)
              this.bagBase64
              that.$getBase64(resp.data[0].bgImg, (dataURL) => {
                // console.log("dataURL=>", dataURL);
                that.bagBase64 = dataURL;
              })
              if (resp.data[0].bsJson) {
                const list = JSON.parse(resp.data[0].bsJson)
                this.list2 = []
                for (let i = 0; i < list.length; i++) {
                  var assessmentIds = []
                  var assessmentNames = []
                  if (list[i].name === 'mokuai46') {
                    for (let i = 0; i < this.assessmentSimpleList.length; i++) {
                      if (i === 0) {
                        assessmentIds = this.assessmentSimpleList[i].id
                        assessmentNames = this.assessmentSimpleList[i].name
                      }
                    }
                  }else{
                    for (let i = 0; i < this.assessmentList.length; i++) {
                      if (i < 5) {
                        assessmentIds.push(this.assessmentList[i].id)
                        assessmentNames.push(this.assessmentList[i].name)
                      }
                    }
                  }
                  console.log(assessmentIds)
                  this.list2.push(list[i])
                  if (currentSubSchoolId == '0') {
                    this.list2[i].assessmentIds = this.assessmentLists.some(ele => ele == this.list2[i].name) ? assessmentIds : []
                    this.list2[i].assessmentNames = this.assessmentLists.some(ele => ele == this.list2[i].name) ? assessmentNames : []
                    this.list2[i].xuekeIds = this.xuekeList[0] ? [this.xuekeList[0].id] : []
                    this.list2[i].xuekeNames = this.xuekeList[0] ? [this.xuekeList[0].name] : []
                    this.list2[i].gradeIds = this.gradeList[0] ? [this.gradeList[0].id] : []
                    this.list2[i].gradeNames = this.gradeList[0] ? [this.gradeList[0].name] : []
                  }
                }
              }
            } else {
              this.$message.error(resp.errMsg);
            }
          },
          (err, code) => {
            console.log("err", err);
          }
        )
      },
      getTemplate() {
        bigScreenUIList(
          {
            subSchoolId: '0',
            useType: this.useType
          },
          (resp) => {
            console.log(resp);
            if (resp.code === 0) {
              this.Template = resp.data
            } else {
              this.$message.error(resp.errMsg);
            }
          },
          (err, code) => {
            console.log("err", err);
          }
        )
      },
      sacleFun() {
        let that = this
        let curNum = 1
        document.onkeydown = function (e) {
          if (e.keyCode === 17) that.ctrlDown = true
        }
        document.onkeyup = function (e) {
          if (e.keyCode === 17) that.ctrlDown = false
        }
        $(".SetBigScreetCenter").on('mousewheel', function (e) {
          // $(window).on('mousewheel',function (e) {
          const event = e.originalEvent
          curNum = that.oriTransNum
          event.preventDefault()
          if (that.ctrlDown) {
            let _newTimes = []
            if (event.deltaY < 0) {  // 放大
              curNum = curNum + that.transNum
              if (curNum > 1) {
                curNum = 1
              }
              that.oriTransNum = curNum
              that.percent = ((curNum * 100).toFixed(0)) * 1
              $('#screen').css(
                {
                  'transform-origin': 'top left',
                  'transform': "scale(" + curNum + ")"
                }
              )
            } else {  // 缩小
              curNum = curNum - that.transNum
              if (0 > curNum) {
                curNum = 0.01
              }
              that.oriTransNum = curNum
              that.percent = ((curNum * 100).toFixed(0)) * 1
              $('#screen').css(
                {
                  'transform-origin': 'top left',
                  'transform': "scale(" + curNum + ")"
                }
              )
            }
          }
        })
      },
      sacleBtnFun(type) {
        const that = this
        let curNum = that.oriTransNum
        if (type === 1) {
          curNum = curNum + that.transNum
          if (curNum > 1) {
            curNum = 1
          }
        } else {
          curNum = curNum - that.transNum
          if (0 > curNum) {
            curNum = 0.01
          }
        }
        that.oriTransNum = curNum
        this.percent = ((curNum * 100).toFixed(0)) * 1
        $('#screen').css(
          {
            'transform-origin': 'top left',
            'transform': "scale(" + curNum + ")"
          }
        )
      },
      sliderChange(e) {
        console.log(e)
        const curNum = e * 0.01
        this.oriTransNum = curNum
        $('#screen').css(
          {
            'transform-origin': 'top left',
            'transform': "scale(" + curNum + ")"
          }
        )
        this.percent = e
      }
    },
    mounted() {
      const that = this
      const record = JSON.parse(this.$route.query.record)
      this.currentSubSchoolId = this.$route.query.currentSubSchoolId
      this.useType = this.$route.query.useType
      this.bigScreenId = record.bigScreenId
      this.domainId = record.domainId
      this.getTemplate()
      this.getModelsQuery()
      if (this.currentSubSchoolId !== '0') {
        this.getXuekeSimpleList()
        this.getGradeListSimple()
        this.getAssessmentSettingListSimple()
        this.getAssessmentListSimple()
      } else {
        this.assessmentLists = []
        this.gradeLists = []
        this.xuekeLists = []
        if (this.$route.query.bigScreenIds) {
          this.loadData('0', this.$route.query.bigScreenIds)
        } else {
          this.loadData(this.currentSubSchoolId, this.bigScreenId)
        }
      }
      window.addEventListener('mousedown', this.mousedown)
      window.addEventListener('click', this.click)
      that.$nextTick(() => {
        that.sacleFun()
      })
    },
    beforeDestroy() {
      window.removeEventListener('mousedown', this.mousedown)
      window.removeEventListener('click', this.click)
    }
  }
</script>
<style scoped>
  .filevideoUrlBox {
    display: flex;
    margin-bottom: 20px;
  }

  .filevideoUrlBox /deep/.ant-upload {
    font-size: 0.7vw;
  }

  .filevideoUrlBox /deep/.ant-btn {
    width: 5vw;
    height: 1.5vw;
    font-size: 0.6vw;
  }

  .filevideoUrlBox /deep/.ant-upload-select-picture-card {
    width: 5vw;
    height: 5vw;
  }

  .SetBigScreetTemplate {
    display: flex;
    position: fixed;
    justify-content: center;
    bottom: 50px;
    left: 0;
    right: 0;
    margin: auto;
    height: 150px;
    width: 75%;
    background-color: rgba(255, 255, 255, 0.3);
    z-index: 999;
    overflow-y: hidden;
    overflow-x: auto;
  }

  .SetBigScreetTemplate>div {
    display: flex;
    align-items: end;
    justify-content: center;
    padding: 0 40px;
    width: 230px;
    height: 150px;
  }

  .SetBigScreetTemplate>div>div {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    cursor: pointer;
    margin-bottom: 10px;
  }

  .SetBigScreetTemplate img {
    width: 100%;
  }

  .SetBigScreet {
    width: 100vw;
    height: 100vh;
    background-color: #282828;
  }

  .SetBigScreetTop {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 10%;
    background-color: #333333;
  }

  .SetBigScreetTopLeft {
    font-size: 1vw;
    color: #fff;
    padding-left: 20px;
    cursor: pointer;
    -moz-user-select: none;
    /* Firefox私有属性 */
    -webkit-user-select: none;
    /* WebKit内核私有属性 */
    -ms-user-select: none;
    /* IE私有属性(IE10及以后) */
    -khtml-user-select: none;
    /* KHTML内核私有属性 */
    -o-user-select: none;
    /* Opera私有属性 */
    user-select: none;
    /* CSS3属性 */
  }

  .SetBigScreetTopRight {
    display: flex;
    color: #fff;
    padding-right: 20px;
  }

  .SetBigScreetTopRightBtn {
    font-size: 0.7vw;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 1vw;
    cursor: pointer;
    -moz-user-select: none;
    /* Firefox私有属性 */
    -webkit-user-select: none;
    /* WebKit内核私有属性 */
    -ms-user-select: none;
    /* IE私有属性(IE10及以后) */
    -khtml-user-select: none;
    /* KHTML内核私有属性 */
    -o-user-select: none;
    /* Opera私有属性 */
    user-select: none;
    /* CSS3属性 */
  }

  .SetBigScreetBox {
    display: flex;
    justify-content: space-between;
    position: relative;
    height: 85%;
  }

  .SetBigScreetLeft {
    position: relative;
    height: calc(100% - 30px);
    width: 10%;
    margin-top: 30px;
    background-color: #333333;
    border-radius: 1vw;
    overflow: hidden;
  }

  .SetBigScreetLeftTop {
    width: 100%;
    height: 5%;
  }

  .SetBigScreetLeftTopTab {
    display: flex;
    justify-content: space-between;
    position: absolute;
    width: 100%;
    height: 5%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #535353;
    padding: 0 20px;
  }

  .SetBigScreetLeftTopTab>div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
    font-size: 0.7vw;
    color: #8b8b8b;
    padding: 12px 0;
    cursor: pointer;
  }

  .tab {
    color: #fff !important;
    border-bottom: 2px solid #3370ff;
  }

  .SetBigScreetLeftList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
    height: 95%;
    overflow-x: hidden;
  }

  .SetBigScreetItemList {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 100%;
  }

  .SetBigScreetLeftBox {
    position: relative;
    width: 70%;
    height: 4vw;
    margin-top: 0.5vw;
    margin-bottom: 0.5vw;
  }

  .SetBigScreetLeftForbid {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    width: 100%;
    height: 80%;
    background-color: rgba(0, 0, 0, 0.3);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .SetBigScreetLeftItem {
    -moz-user-select: none;
    /* Firefox私有属性 */
    -webkit-user-select: none;
    /* WebKit内核私有属性 */
    -ms-user-select: none;
    /* IE私有属性(IE10及以后) */
    -khtml-user-select: none;
    /* KHTML内核私有属性 */
    -o-user-select: none;
    /* Opera私有属性 */
    user-select: none;
    /* CSS3属性 */
    width: 100%;
    height: 90%;
    border-radius: 10px;
    overflow: hidden;
    margin: 10px 0;
    cursor: pointer;
  }

  .SetBigScreetLeftItemImg {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 70%;
    overflow: hidden;
    background-color: #1c1c1c;
    pointer-events: none;
  }

  .SetBigScreetLeftItemName {
    width: 100%;
    height: 30%;
    background-color: #282828;
    color: #a5a5a5;
    text-align: center;
    /* display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none; */
    font-size: 0.7vw;
    overflow: hidden;
    text-overflow:ellipsis;
    white-space: nowrap;
  }

  .SetBigScreetRight {
    position: relative;
    height: calc(100% - 30px);
    width: 10%;
    margin-top: 30px;
    overflow-x: auto;
    background-color: #333333;
    border-radius: 1vw;
  }

  .SetBigScreetRightSet {
    padding: 10px;
  }

  .SetBigScreetRightSetTiele {
    font-size: 0.7vw;
    color: #b0b0b0;
    margin: 5px 0;
  }

  .SetBigScreetRightSetCkenk {
    width: 0.7vw;
    height: 0.7vw;
  }

  .SetBigScreetRightSetCkenk /deep/.ant-checkbox-inner {
    width: 0.7vw;
    height: 0.7vw;
  }

  .SetBigScreetRightSetCkenk /deep/.ant-checkbox-inner::after {
    width: 0.3vw;
    height: 0.3vw;
  }

  .SetBigScreetRightSetInput {
    width: 90%;
    font-size: 0.7vw;
    min-height: 2vw;
    color: #b0b0b0;
    background-color: #242424;
    border: 1px solid #4d4d4d;
    margin: 5px;
  }

  .SetBigScreetRightSetInput /deep/.ant-select-selection {
    background-color: #242424;
    border: none;
  }

  .SetBigScreetRightSetInput /deep/.ant-select-selection__choice {
    background-color: #1a1a1a;
    border: 1px solid #4d4d4d;
    color: #b0b0b0;
  }

  .SetBigScreetRightSetInput /deep/.ant-select-selection__clear {
    background-color: #1a1a1a;
    color: #b0b0b0;
  }

  .SetBigScreetRightSetInput /deep/.ant-select-selection--single {
    height: 2vw;
  }

  .SetBigScreetRightSetInput /deep/.ant-select-selection__rendered {
    height: 2vw;
  }

  .SetBigScreetRightSetInput /deep/.ant-select-selection-selected-value {
    height: 2vw;
    line-height: 2vw;
  }

  .SetBigScreetRightSetInput /deep/.ant-input-number-input-wrap {
    height: 2vw;
  }

  .SetBigScreetRightSetInput /deep/.ant-input-number-input {
    height: 2vw;
  }

  .SetBigScreetRightSetInput /deep/.ant-select-selection__choice {
    height: 2vw;
  }

  .SetBigScreetRightSetInput /deep/.ant-select-selection__choice__content {
    height: 2vw;
    line-height: 2vw;
  }

  .SetBigScreetRightSetBtn {
    width: 3vw;
    height: 1.5vw;
    margin-top: 20px;
    float: right;
    margin-right: 15px;
    font-size: 0.6vw;
  }

  .SetBigScreetRightTop {
    width: 100%;
    height: 5%;
  }

  .SetBigScreetRightTopTab {
    display: flex;
    justify-content: center;
    position: absolute;
    width: 100%;
    height: 5%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    background-color: #535353;
    padding: 0 20px;
  }

  .SetBigScreetRightTopTab>div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    padding: 12px 0;
    cursor: pointer;
    font-size: 0.7vw;
  }

  .SetBigScreetCenter {
    height: 100%;
    width: 75%;
    overflow: auto;
    margin-top: 30px;
    background-color: #ccc;
  }

  .progressBox {
    position: absolute;
    right: 330px;
    bottom: 35px;
    width: 270px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 9999;
  }

  .progressBox_btnBox {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3px;
    border-radius: 2px;
    border: 1px solid #ccc;
    cursor: pointer;
  }

  .progressBox_progress {
    margin: 0 10px;
  }

  .progressBox_btn {}

  #screen {
    position: relative;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    margin: auto;
    background-color: #fff;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .uploadBox {
    margin-top: 20px;
  }

  .uploadBox_imgBox {
    display: flex;
    flex-wrap: wrap;
  }

  .uploadBox_imgBox_img {
    width: 4vw;
    margin-right: 5px;
    margin-bottom: 10px;
  }

  .progressBox_bar {
    width: calc(100% - 104px);
    margin: 0 20px;
  }

  .progressBox_bar_span {
    color: #fff;
    display: inline-block;
    margin-right: 10px;
  }
</style>
<style>
  .progressBox .ant-progress-text {
    color: #fff;
  }

  .ant-select-dropdown-menu-item {
    background-color: #242424;
    color: #b0b0b0 !important;
    font-size: 0.7vw;
    line-height: 1.5vw;
  }

  .ant-select-dropdown {
    background-color: #242424;
  }

  .ant-select-dropdown-menu-item-selected {
    background-color: #1a1a1a;
    color: #294ea4 !important;
  }

  .ant-select-dropdown-menu-item-active {
    background-color: #1a1a1a !important;
    color: #294ea4 !important;
  }

  .top {
    position: relative;
  }

  .titleImg {
    width: 100%;
  }

  .bgImg {
    width: 100%;
    height: 100%;
  }

  .titlePosition {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 2em;
    margin: auto;
    display: flex;
    align-items: center;
    width: 100%;
    color: #fff;
  }

  .titlePositionCenter {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 13%;
    width: 100%;
  }

  .namePosition {
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    margin: auto;
  }

  .title {
    font-size: 1em;
    font-weight: 700;
    color: #fff;
  }

  .title1 {
    font-size: 0.7em;
    color: #fff;
  }

  .mokuaiImg {
    width: 100%;
  }

  .mokuaiMp4 {
    width: 100%;
  }

  .mokuaiMp4Position {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 10%;
    margin: auto;
    width: 90%;
    height: 75%;
    object-fit: fill;
  }

  .videoimg {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .itemBox {
    width: 100%;
    height: 100%;
    position: relative;
    pointer-events: none;
  }

  .itemBoxDel {
    -moz-user-select: none;
    /* Firefox私有属性 */
    -webkit-user-select: none;
    /* WebKit内核私有属性 */
    -ms-user-select: none;
    /* IE私有属性(IE10及以后) */
    -khtml-user-select: none;
    /* KHTML内核私有属性 */
    -o-user-select: none;
    /* Opera私有属性 */
    user-select: none;
    /* CSS3属性 */
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 20px;
    color: red;
    pointer-events: all;
    cursor: pointer;
    z-index: 10;
  }

  .itemBoxRight {
    display: inline-block;
    position: absolute;
    top: 0;
    right: -10px;
    bottom: 0;
    margin: auto;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #bdd10c;
    pointer-events: all;
    cursor: pointer;
    z-index: 10;
  }

  .itemBoxBottom {
    display: inline-block;
    position: absolute;
    bottom: -10px;
    right: 0;
    left: 0;
    margin: auto;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #bdd10c;
    pointer-events: all;
    cursor: pointer;
    z-index: 10;
  }

  .itemBoxRightBottom {
    display: inline-block;
    position: absolute;
    bottom: -10px;
    right: -10px;
    margin: auto;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #bdd10c;
    pointer-events: all;
    cursor: pointer;
    z-index: 10;
  }

  .itemBoxComparison {
    position: relative;
    width: 100%;
    height: 100%;
    -moz-user-select: none;
    /* Firefox私有属性 */
    -webkit-user-select: none;
    /* WebKit内核私有属性 */
    -ms-user-select: none;
    /* IE私有属性(IE10及以后) */
    -khtml-user-select: none;
    /* KHTML内核私有属性 */
    -o-user-select: none;
    /* Opera私有属性 */
    user-select: none;
    /* CSS3属性 */
  }

  .itemBoxComparisonTop {
    display: none;
    position: absolute;
    top: 0;
    left: -2000px;
    right: 0;
    margin: auto;
    height: 0px;
    width: 99999px;
    border-top: 1px solid #fff;
    pointer-events: none;
  }

  .itemBoxComparisonBottom {
    display: none;
    position: absolute;
    bottom: 0;
    left: -2000px;
    right: 0;
    margin: auto;
    height: 0px;
    width: 99999px;
    border-bottom: 1px solid #fff;
    pointer-events: none;
  }

  .itemBoxComparisonLeft {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    height: 99999px;
    width: 0px;
    border-left: 1px solid #fff;
    pointer-events: none;
  }

  .itemBoxComparisonRight {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 99999px;
    width: 0px;
    border-right: 1px solid #fff;
    pointer-events: none;
  }

  .move .itemBoxComparisonTop {
    display: block;
  }

  .move .itemBoxComparisonBottom {
    display: block;
  }

  .move .itemBoxComparisonLeft {
    display: block;
  }

  .move .itemBoxComparisonRight {
    display: block;
  }

  .progressBox_bar .ant-slider-handle {
    border: solid 2px #52c41a;
  }

  .progressBox_bar .ant-slider-track {
    background-color: #52c41a;
  }

  .progressBox_bar .ant-slider:hover .ant-slider-handle:not(.ant-tooltip-open) {
    border-color: #52c41a;
  }

  .progressBox_bar .ant-slider:hover .ant-slider-track {
    background-color: #52c41a;
  }
</style>