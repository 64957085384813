<template>
  <div
    class="mokuai5"
    :id="item.id"
    :style="{
      top: item.top + 'px',
      left: item.left + 'px',
      width: item.width + 'px',
      height: item.height + 'px',
      'font-size': item.fontSize + 'px',
    }"
  >
    <div class="itemBox">
      <span class="itemBoxDel" v-if="!isImg">X</span>
      <span v-if="item.fixedRatio == 1 && !isImg" class="itemBoxRightBottom"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxRight"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxBottom"></span>
      <div class="itemBoxComparison">
        <div class="dataScreenBox">
          <span class="dataScreenSpan">{{ dateText }}</span>
          <span class="dataScreenSpan" style="margin: 0 15px">{{
            timeText
          }}</span>
          <span class="dataScreenSpan">{{ weekText }}</span>
        </div>
      </div>
      <div class="itemBoxComparisonTop"></div>
      <div class="itemBoxComparisonBottom"></div>
      <div class="itemBoxComparisonRight"></div>
      <div class="itemBoxComparisonLeft"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mokuai5",
  props: {
    item: {
      type: Object,
    },
    isImg: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      // 日期
      dateText: "",
      // 时间
      timeText: "",
      // 周几
      weekText: ""
    };
  },
  methods: {
    getDate() {
      var myDate = new Date();
      var year = myDate.getFullYear(); // 获取当前年
      var mon = myDate.getMonth() + 1; // 获取当前月
      var date = myDate.getDate(); // 获取当前日
      var h = myDate.getHours(); // 获取当前小时数(0-23)
      var m = myDate.getMinutes(); // 获取当前分钟数(0-59)
      var s = myDate.getSeconds(); // 获取当前秒
      var week = myDate.getDay();
      var weeks = [
        "星期日",
        "星期一",
        "星期二",
        "星期三",
        "星期四",
        "星期五",
        "星期六",
      ];
      // 日期
      if (mon.toString().length < 2) {
        mon = "0" + mon;
      }
      if (date.toString().length < 2) {
        date = "0" + date;
      }
      this.dateText = year + "-" + mon + "-" + date;
      // 时间
      if (h.toString().length < 2) {
        h = "0" + h;
      }
      if (m.toString().length < 2) {
        m = "0" + m;
      }
      if (s.toString().length < 2) {
        s = "0" + s;
      }
      this.timeText = h + ":" + m + ":" + s;
      // 星期几
      this.weekText = weeks[week];
    },
  },
  mounted() {
    const that = this;
    setInterval(function () {
      that.getDate();
    }, 1000);
  },
};
</script>

<style>
.mokuai5 {
  position: absolute;
  display: block;
  cursor: move;
  font-size: 16px;
}
.dataScreenBox {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.dataScreenSpan {
  color: #ccecf9;
  font-size: 2.5em;
}
</style>
