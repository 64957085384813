
<template>
    <div class="mokuai1" :id="item.id" :style="{'top': item.top + 'px','left': item.left + 'px','font-size': item.fontSize + 'px'}" v-if="!isImg">
        <div class="itemBox">
            <span class="itemBoxDel" v-if="!isImg">X</span>
            <!-- <span class="itemBoxRight"></span>
            <span class="itemBoxBottom"></span> -->
            <div class="itemBoxComparison">
              <div class="top" style="height: 80%;width: 100%;display: flex;align-items: center;justify-content: center;position: absolute;">
                <div class="titleTop">{{ item.title }}</div>
              </div>
              <img :src="videoImg" class="mokuaiMp4" alt="" v-if="isImg">
              <video :src="item.mp4" class="mokuaiMp4" autoplay loop muted v-if="!isImg&&item.name=='mokuai1'">
              </video>
              <img :src="item.img" class="mokuaiMp4" alt="" v-if="!isImg&&(item.name=='mokuai25' || item.name=='mokuai31' || item.name=='mokuai33' || item.name=='mokuai53' || item.name=='mokuai66' || item.name=='mokuai67' || item.name=='mokuai68' || item.name=='mokuai99')">
            </div>
            <div class="itemBoxComparisonTop"></div>
            <div class="itemBoxComparisonBottom"></div>
            <div class="itemBoxComparisonRight"></div>
            <div class="itemBoxComparisonLeft"></div>
        </div>
    </div>
  </template>
  <script>
  export default {
    name: 'mokuai1',
    props:{
      item: {
        type: Object,
      },
      isImg: {
        type: Boolean
      }
    },
    components: {},
    data() {
      return {
        videoImg: "",
      }
    },
    mounted() {
    const that = this;
    that.$getBase64(that.item.img, (dataURL) => {
      that.videoImg = dataURL;
    });
  },
  }
  </script>
  
  <style>
  .mokuai1 {
    position: absolute;
    display: block;
    width: 100%;
    cursor: move;
    font-size: 16px;
  }
  .titleTop {
    font-size: 2.5em;
    width: 100%;
    text-align: center;
    color: #fff;
    letter-spacing: 8px;
    background-image:-webkit-linear-gradient(top, #fff,#fff,#8beef8);
    -webkit-background-clip:text;
    -webkit-text-fill-color:transparent;
    font-weight: 700;
    -webkit-transform: skew(-10deg); /* for Chrome||Safari */
    -ms-transform: skew(-10deg); /* for IE */
    -moz-transform:skew(-10deg);/* for Firefox */
    -o-transform:skew(-10deg);/* for Opera */
  }
  </style>
  