<template>
  <div
    class="mokuai72"
    :id="item.id"
    :style="{
      top: item.top + 'px',
      left: item.left + 'px',
      width: item.width + 'px',
      height: item.height + 'px',
      'font-size': item.fontSize + 'px',
    }"
  >
    <div class="itemBox">
      <span class="itemBoxDel" v-if="!isImg">X</span>
      <span v-if="item.fixedRatio == 1 && !isImg" class="itemBoxRightBottom"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxRight"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxBottom"></span>
      <div class="itemBoxComparison top">
        <img
          class="bgImg"
          src="../../assets/img/imgStaly1/jiaoxue_jiucuo_bg.png"
          alt=""
        />
        <div
          class="titlePositionCenter"
        >
          <span class="title" style="font-size: 1.5em;">{{ item.title }}</span>
        </div>
        <img :src="videoImg" class="mokuaiMp4Position" style="width: 80%;" v-if="isImg" />
        <video :src="item.mp4" class="mokuaiMp4Position" style="width: 80%;" autoplay loop muted v-else>
        </video>
      </div>
      <div class="itemBoxComparisonTop"></div>
      <div class="itemBoxComparisonBottom"></div>
      <div class="itemBoxComparisonRight"></div>
      <div class="itemBoxComparisonLeft"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: "mokuai72",
  props: {
    item: {
      type: Object,
    },
    isImg: {
      type: Boolean
    }
  },
  components: {},
  data() {
    return {
      videoImg: "",
    };
  },
  mounted() {
    const that = this;
    that.$getBase64(that.item.img, (dataURL) => {
      // console.log("dataURL=>", dataURL);
      that.videoImg = dataURL;
    });
  },
};
</script>

<style>
.mokuai72 {
  position: absolute;
  display: block;
  cursor: move;
  font-size: 16px;
  text-align: center;
}
</style>
