<template>
  <div
    class="mokuai23"
    :id="item.id"
    :style="{
      top: item.top + 'px',
      left: item.left + 'px',
      width: item.width + 'px',
      height: item.height + 'px',
      'font-size': item.fontSize + 'px',
    }"
  >
    <div class="itemBox">
      <span class="itemBoxDel" v-if="!isImg">X</span>
      <span v-if="item.fixedRatio == 1 && !isImg" class="itemBoxRightBottom"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxRight"></span>
      <span v-if="item.fixedRatio != 1 && !isImg" class="itemBoxBottom"></span>
      <div class="itemBoxComparison">
        <div class="top" style="height: 15%;">
          <TitlesImg :type="item.titleImgType" />
          <div class="titlePosition" style="margin-left: 2em;">
            <span class="title">{{ item.title }}</span>
          </div>
        </div>
        <img :src="videoImg" style="object-fit: fill;width: 100%;height: 85%;" v-if="isImg" />
        <video :src="item.mp4" style="object-fit: fill;width: 100%;height: 85%;" autoplay loop muted v-else>
        </video>
      </div>
      <div class="itemBoxComparisonTop"></div>
      <div class="itemBoxComparisonBottom"></div>
      <div class="itemBoxComparisonRight"></div>
      <div class="itemBoxComparisonLeft"></div>
    </div>
  </div>
</template>
<script>
import TitlesImg from '@/components/CustomModule/TitlesImg.vue'
export default {
  name: "mokuai23",
  props: {
    item: {
      type: Object,
    },
    isImg: {
      type: Boolean
    }
  },
  components: { TitlesImg },
  data() {
    return {
      videoImg: "",
    };
  },
  mounted() {
    const that = this;
    that.$getBase64(that.item.img, (dataURL) => {
      // console.log("dataURL=>", dataURL);
      that.videoImg = dataURL;
    });
  },
};
</script>

<style>
.mokuai23 {
  position: absolute;
  display: block;
  cursor: move;
  font-size: 16px;
}
</style>
