<template>
  <a-modal :title="title" :width="550" :visible="visible" @ok="handleSubmit" @cancel="handleCancel"
    :confirmLoading="confirmLoading">
    <div style="display: flex;align-items: center;" v-if="cType==0">
      <div>
        填写网址：
      </div>
      <div>
        <a-input addon-before="http://" addon-after="daping.xueyoyo.com" placeholder="只支持小写英文字母" v-model="http"
          :maxLength="20" @change="httpChange" />
      </div>
    </div>
    <div v-if="cType==1" style="display: flex;align-items: center;">
      
    </div>
  </a-modal>
</template>

<script>
  import {
    bigScreenUrlChange,
    bclassSimple
  } from '@/api/other'
  export default {
    data() {
      return {
        title: '',
        http: undefined,
        id: '0',
        visible: false,
        confirmLoading: false,
        iswarning: true,
        currentSubSchoolId: '',
        bclassId: undefined,
        cType: ''
      }
    },
    methods: {
      httpChange() {
        this.http = this.http.replace(/[^a-z]/g, '')
      },
      showModel(subSchoolId, type) {
        this.visible = true
        this.currentSubSchoolId = subSchoolId
        this.bclassId = localStorage.getItem('bclassId') * 1
        this.cType = type * 1
        if (this.cType === 1) {
          this.title = '电子班牌发布'
          this.handleSubmit()
        } else {
          this.title = '大屏发布'
        }
      },
      editModel(subSchoolId, item, type) {
        console.log(item)
        this.visible = true
        this.currentSubSchoolId = subSchoolId
        this.id = item.domainId
        this.http = item.domain 
        this.cType = type * 1
        if (this.cType === 1) {
          this.title = '电子班牌发布'
         this.bclassId = item.bclassId
        } else {
          this.title = '大屏发布'
        }
      },
      getRequest() {
    var url = window.location.search;
    var theRequest = new Object();
    if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
            theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
        }
    }
    return theRequest;
},
      handleSubmit() {
        const that = this
        if (this.cType === 1) {
          if (!this.bclassId) {
            this.$message.warning('请选择年级')
            return
          }
        } else {
          if (!this.http) {
            this.$message.warning('请输入网址')
            return
          }
        }
        if (this.iswarning && this.cType === 0) {
          this.$confirm({
            title: '警告',
            icon: `info-circle`,
            content: '修改网址会使旧有大屏网址失效，确定修改？',
            onOk() {
              that.setBigScreenUrlChange()
            },
            onCancel() {}
          })
        } else {
          this.setBigScreenUrlChange()
        }
      },
      setBigScreenUrlChange() {
        this.confirmLoading = true
        let urls = ''
        if (this.cType === 0) {
          urls = this.http
        } else {
          urls = 'http://classboard.xueyoyo.com/index.html?id=' + this.bclassId
          // urls = 'http://dping-show.xueyoyo.com.cn/index.html?id=' + this.bclassId
        }
        bigScreenUrlChange({
            subSchoolId: this.currentSubSchoolId,
            domainId: this.id,
            domainName: urls,
            useType: this.cType
          },
          (resp) => {
            this.confirmLoading = false
            this.$message.success('操作成功')
            this.visible = false
            if (this.cType === 1) {
              localStorage.setItem('bclassId', this.bclassId)
            }
            this.$emit('close', resp.data)
          },
          (err) => {
            console.log(err)
            this.confirmLoading = false
          }
        )
      },
      handleCancel() {
        this.visible = false
        this.$emit('close')
      }
    },
    mounted() {}
  }

</script>

<style scoped>

</style>
